import { useEffect, useMemo, useRef, useState } from 'react';
import { HandCoins, ReceiptCent } from 'lucide-react';

import { SymbolsParameterChart } from '@/components/blocks/SymbolsParameterChart/SymbolsParameterChart';
import { SymbolsSelect } from '@/components/blocks/SymbolsSelect/SymbolsSelect';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  aggregateValuesBySymbol,
  getHourlyPeriodRanges,
  useTradeBalances,
} from '@/hooks/useStatistic';
import { IncomeTypeEnum, ReportResponse } from '@/types';

import { SymbolsTotalParameterChart } from './SymbolsTotalParameterChart';
import { usePositions } from '@/hooks/usePositions';

type SymbolsReportChartProps = { reportData?: ReportResponse; fromTs: number; toTs: number };
export const SymbolsReportChart = ({ reportData, fromTs, toTs }: SymbolsReportChartProps) => {
  const [statisticType, setStatisticType] = useState('trade-balance');
  const [isAggregate, setIsAggregate] = useState(true);
  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);
  const {
    currentPositionsSymbols,
    positionsRequest: { isLoading: isPositionsLoading },
  } = usePositions();
  const isInit = useRef(false);

  const {
    tradeBalances,
    tradeBalancesRequest: { isLoading },
  } = useTradeBalances(fromTs - 3600_000, toTs); // получаем за 1 час до fromTs, чтобы при округлении все periodDates были заполнены

  const totalSymbols = useMemo(() => {
    const symbols = [...tradeBalances, ...(reportData?.incomes || [])].reduce((acc, { symbol }) => {
      if (symbol) acc.add(symbol);
      return acc;
    }, new Set<string>());

    return [...symbols].sort();
  }, [reportData, tradeBalances]);

  useEffect(() => {
    if (!isInit.current && totalSymbols.length && !isPositionsLoading) {
      setSelectedSymbols(currentPositionsSymbols || totalSymbols);
      isInit.current = true;
    }
  }, [totalSymbols, currentPositionsSymbols, isPositionsLoading, selectedSymbols]);

  const periodDates = useMemo(() => getHourlyPeriodRanges(fromTs, toTs), [fromTs, toTs]);

  const tradeBalancesByPeriods = useMemo(() => {
    const selectedTradeBalances = tradeBalances
      .filter(tb => selectedSymbols.includes(tb.symbol))
      .sort((a, b) => new Date(a.dt).getTime() - new Date(b.dt).getTime());

    const result = [];
    let prevSymbolTradeBalances = {};

    for (const period of periodDates) {
      const lastChunkIndex = selectedTradeBalances.findIndex(t => new Date(t.dt) >= period.endDate);
      const chunk = selectedTradeBalances.splice(
        0,
        lastChunkIndex > -1 ? lastChunkIndex : selectedTradeBalances.length
      );

      const symbolTradeBalances = chunk.reduce(
        (acc, { symbol, tradeBalance }) => {
          acc[symbol] = tradeBalance;
          return acc;
        },
        prevSymbolTradeBalances as Record<string, number>
      );

      prevSymbolTradeBalances = JSON.parse(JSON.stringify(symbolTradeBalances));

      result.push({
        date: period.startDate,
        endDate: period.endDate,
        values: symbolTradeBalances,
      });
    }
    return result;
  }, [periodDates, selectedSymbols, tradeBalances]);

  const incomesByPeriods = useMemo(() => {
    const selectedIncomes = [...(reportData?.incomes || [])]
      .filter(c => c.symbol && selectedSymbols.includes(c.symbol))
      .sort((a, b) => a.serverTimestamp - b.serverTimestamp);

    return periodDates.map(({ startDate, endDate }) => {
      const lastIncomeChunkIndex = selectedIncomes.findIndex(
        i => new Date(i.serverTimestamp) >= endDate
      );
      const incomesChunk = selectedIncomes.splice(
        0,
        lastIncomeChunkIndex > -1 ? lastIncomeChunkIndex : selectedIncomes.length
      );

      return {
        startDate,
        endDate,
        incomes: incomesChunk,
      };
    });
  }, [periodDates, reportData, selectedSymbols]);

  const symbolsFundingByPeriods = useMemo(
    () =>
      incomesByPeriods.map(({ startDate, endDate, incomes }) => {
        const values = incomes.reduce(
          (acc, income) => {
            if (income.symbol && income.incomeType == IncomeTypeEnum.FUNDING_FEE) {
              if (income.symbol in acc) acc[income.symbol] += income.income;
              else acc[income.symbol] = income.income;
            }
            return acc;
          },
          Object.fromEntries(selectedSymbols.map(s => [s, 0])) as Record<string, number>
        );

        return {
          date: startDate,
          endDate,
          values,
        };
      }),
    [incomesByPeriods, selectedSymbols]
  );

  const totalTradeBalance = useMemo(
    () => tradeBalancesByPeriods[tradeBalancesByPeriods.length - 1].values,
    [tradeBalancesByPeriods]
  );

  const totalFundingData = useMemo(
    () => aggregateValuesBySymbol(symbolsFundingByPeriods),
    [symbolsFundingByPeriods]
  );

  const historyData = useMemo(() => {
    if (statisticType === 'trade-balance') return tradeBalancesByPeriods;
    if (statisticType === 'funding') return symbolsFundingByPeriods;
    return [];
  }, [statisticType, tradeBalancesByPeriods, symbolsFundingByPeriods]);

  return (
    <div className="flex w-full flex-col">
      {isLoading ? <CenteredSpinner /> : null}

      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-4">
          <Tabs
            variant="filled"
            value={statisticType}
            onValueChange={value => setStatisticType(value)}
          >
            <TabsList className="gap-1">
              <TabsTrigger value="trade-balance">
                <div className="flex items-center">
                  <ReceiptCent className="mr-2 size-5" /> Trade Balance
                </div>
              </TabsTrigger>
              <TabsTrigger value="funding">
                <div className="flex items-center">
                  <HandCoins className="mr-2 size-5" /> Funding
                </div>
              </TabsTrigger>
            </TabsList>
          </Tabs>

          {statisticType == 'funding' ? (
            <Switch
              label="Aggregate"
              size="sm"
              checked={isAggregate}
              onCheckedChange={setIsAggregate}
            />
          ) : null}
        </div>

        <div className="flex items-center gap-2">
          <span className="text-sm text-secondary-foreground">Symbols:</span>
          <SymbolsSelect
            symbols={totalSymbols}
            selected={selectedSymbols}
            onSelect={setSelectedSymbols}
          />
        </div>
      </div>

      <div className="mt-4 flex gap-4">
        <div className="shrink-0 grow-0 basis-[260px]">
          {statisticType === 'trade-balance' ? (
            <SymbolsTotalParameterChart label="Trade Balance" data={totalTradeBalance} isCurrency />
          ) : null}
          {statisticType === 'funding' ? (
            <SymbolsTotalParameterChart label="Funding" data={totalFundingData} isCurrency />
          ) : null}
        </div>
        <div className="grow">
          <SymbolsParameterChart
            label={statisticType}
            data={historyData}
            symbols={selectedSymbols}
            isCurrency
            mode={statisticType == 'funding' && isAggregate ? 'aggregate' : 'absolute'}
          />
        </div>
      </div>
    </div>
  );
};
