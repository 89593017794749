import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import {
  ColorType,
  createChart,
  CrosshairMode,
  IChartApi,
  IPriceLine,
  ISeriesApi,
  LineStyle,
  SeriesMarker,
  UTCTimestamp,
} from 'lightweight-charts';

import { useThemeContext } from '@/context/ThemeContext';
import { COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from '@/lib/colors';
import { formatDate, isReduceOrder } from '@/lib/common';
import { IndicatorValue, KlineIntervalEnum, Order, OrderTypeEnum } from '@/types';

import { defaultChartColors, defaultDarkChartColors, KlineData } from './commonChartProps';

const defaultGridLineProps = {
  color: '#3b82f6',
  lineStyle: LineStyle.Solid,
  axisLabelVisible: true,
};
const defaultOrderLineProps = {
  // color: 'green' | 'red' \ 'orange',
  lineStyle: LineStyle.Solid,
  axisLabelVisible: true,
};

const defaultCandlestickProps = {
  upColor: COLOR_GREEN,
  downColor: COLOR_RED,
  borderVisible: false,
  wickUpColor: COLOR_GREEN,
  wickDownColor: COLOR_RED,
};
const ALGO_ORDER_COLOR = COLOR_YELLOW;
const REDUCE_ORDER_COLOR = COLOR_RED;
const BUY_ORDER_COLOR = COLOR_GREEN;

type ChartIndicatorValue = Pick<IndicatorValue, 'value'> & { time: UTCTimestamp };
type ChartIndicator = { type: string; params: any; data: ChartIndicatorValue[] };
export interface PriceChartComponentHandle {
  scaleFit: () => void;
}

type PriceChartComponentProps = {
  colors?: Record<string, string>;
  symbol: string;
  interval: KlineIntervalEnum;

  data: KlineData[];
  markers?: SeriesMarker<UTCTimestamp>[];
  grid?: number[];
  orders?: Order[];
  indicators?: ChartIndicator[];
};
export const PriceChart = forwardRef<PriceChartComponentHandle, PriceChartComponentProps>(
  ({ colors, symbol, interval, data, markers, grid, orders, indicators }, ref) => {
    const { isDark } = useThemeContext();
    const chartColors = useMemo(
      () => ({ ...(isDark ? defaultDarkChartColors : defaultChartColors), ...colors }),
      [isDark, colors]
    );

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi>();
    const seriesRef = useRef<ISeriesApi<any>>();

    useEffect(() => {
      if (chartContainerRef.current) {
        const { crosshairLabelColor, backgroundColor, lineColor, textColor } = chartColors;

        chartRef.current = createChart(chartContainerRef.current, {
          localization: {
            timeFormatter: (time: any) => formatDate(new Date(time * 1000), { withYear: true }),
          },
          layout: {
            background: { type: ColorType.Solid, color: backgroundColor },
            textColor,
            // attributionLogo: false,
          },
          grid: {
            vertLines: { color: lineColor },
            horzLines: { color: lineColor },
          },
          crosshair: {
            mode: CrosshairMode.Normal,
            vertLine: { labelBackgroundColor: crosshairLabelColor },
            horzLine: { labelBackgroundColor: crosshairLabelColor },
          },
          // handleScale: false,
          leftPriceScale: {
            autoScale: false,
            // alignLabels: false,
            scaleMargins: { top: 0, bottom: 0 },
          },
          autoSize: true,
        });
      }

      return () => {
        if (seriesRef.current) {
          chartRef.current?.removeSeries(seriesRef.current);
        }
        chartRef.current?.remove();
        seriesRef.current = undefined;
      };
    }, [chartColors]);

    useEffect(() => {
      if (chartRef.current && seriesRef.current) {
        chartRef.current.removeSeries(seriesRef.current);
        seriesRef.current = undefined;
      }
    }, [symbol, interval]);

    useEffect(() => {
      if (!data || !data.length) return;

      if (chartRef.current) {
        const isSameDataset = Boolean(seriesRef.current);

        if (!seriesRef.current) {
          seriesRef.current = chartRef.current.addCandlestickSeries(defaultCandlestickProps);
        }

        if (isSameDataset) {
          const lastData = data[data.length - 1];
          seriesRef.current.update(lastData);
        } else {
          seriesRef.current.setData(data);
          let precision = 2;
          let minMove = 0.01;
          if (data.length) {
            if (data[data.length - 1].open < 1) {
              precision = 4;
              minMove = 0.0001;
            }
            if (data[data.length - 1].open < 0.1) {
              precision = 5;
              minMove = 0.00001;
            }
          }
          seriesRef.current.applyOptions({ priceFormat: { precision, minMove } });
        }
      }
    }, [data, isDark]);

    useEffect(() => {
      if (markers) {
        if (seriesRef.current) {
          seriesRef.current.setMarkers(markers);
        } else {
          console.warn('No series ref for markers');
        }
      }

      return () => {
        seriesRef.current?.setMarkers([]);
      };
    }, [markers, isDark]);

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.applyOptions({
          leftPriceScale: { visible: Boolean(indicators?.length) },
        });
      }

      let chartIndicators: ISeriesApi<'Line'>[] = [];
      if (indicators) {
        if (seriesRef.current) {
          chartIndicators = indicators
            .map(indicator => {
              if (!chartRef.current) return;

              if (indicator.type == 'line') {
                const lineSeries = chartRef.current.addLineSeries({
                  ...indicator.params,
                  priceScaleId: 'left',
                  autoscaleInfoProvider: () => ({
                    priceRange: {
                      minValue: 0,
                      maxValue: 100,
                    },
                    margins: {
                      above: 0,
                      below: 0,
                    },
                  }),
                });
                lineSeries.setData(indicator.data);
                return lineSeries;
              } else {
                console.error('Unknown indicator type:', indicator.type);
              }
            })
            .filter(i => i !== undefined) as unknown as any;

          chartRef.current?.priceScale('left').applyOptions({ autoScale: true });
        } else {
          console.warn('No series ref for indicators');
        }
      }

      return () => {
        chartIndicators.forEach(indicatorSeries => {
          try {
            chartRef.current?.removeSeries(indicatorSeries);
          } catch (e) {
            console.warn(e);
          }
        });
      };
    }, [indicators, isDark]);

    useEffect(() => {
      let gridLines: IPriceLine[] = [];
      if (grid) {
        if (seriesRef.current) {
          gridLines = grid.map((price, index) =>
            seriesRef.current!.createPriceLine({
              price: price,
              title: `${index}`,
              ...defaultGridLineProps,
            })
          );
        } else {
          console.warn('No series ref for grid lines');
        }
      }

      return () => {
        gridLines.map(pl => seriesRef.current?.removePriceLine(pl));
      };
    }, [grid, isDark]);

    useEffect(() => {
      let orderLines: IPriceLine[] = [];
      if (orders) {
        if (seriesRef.current) {
          orderLines = orders.map(order => {
            const isReduce = isReduceOrder(order);
            const isAlgo = ![OrderTypeEnum.LIMIT, OrderTypeEnum.MARKET].includes(order.type);
            const color = isAlgo
              ? ALGO_ORDER_COLOR
              : isReduce
                ? REDUCE_ORDER_COLOR
                : BUY_ORDER_COLOR;
            const price = isAlgo ? order.stopPrice : order.price;

            return seriesRef.current!.createPriceLine({
              price,
              color,
              title: isAlgo ? `${order.type} ${order.positionSide}` : `${order.positionSide}`,
              ...defaultOrderLineProps,
            });
          });
        } else {
          console.warn('No series ref for order lines');
        }
      }

      return () => {
        orderLines.map(pl => seriesRef.current?.removePriceLine(pl));
      };
    }, [orders, isDark]);

    const scaleFit = useCallback(() => {
      if (chartRef.current) {
        chartRef.current.priceScale('right').applyOptions({ autoScale: true });
        chartRef.current.priceScale('left').applyOptions({ autoScale: true });

        // Показываем 100 последних свечей
        if (seriesRef.current) {
          const currentData = seriesRef.current.data();
          const currentFromData =
            currentData.length > 100 ? currentData[currentData.length - 100] : currentData[0];
          const currentToData = currentData[currentData.length - 1];

          if (currentFromData && currentToData) {
            chartRef.current.timeScale().setVisibleRange({
              from: currentFromData.time,
              to: currentToData.time,
            });
          }
          // Иначе просто отматываем в текущее время
        } else {
          chartRef.current.timeScale().scrollToRealTime();
        }
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        scaleFit,
      }),
      [scaleFit]
    );

    return <div className="h-full" ref={chartContainerRef} />;
  }
);
PriceChart.displayName = 'PriceChart';
