import * as React from 'react';

import { cn } from '@/lib/utils';
import { SortingType } from '@/types';
import { ArrowDownNarrowWide, ArrowDownUp, ArrowDownWideNarrow } from 'lucide-react';

interface TableContextProps {
  sorting?: SortingType<any>;
  setSorting: (sorting?: SortingType<any>) => void;
  emitSorting?: (sorting?: SortingType<any>) => void;
}

const TableContext = React.createContext<TableContextProps | undefined>(undefined);

export const TableSortProvider: React.FC<{
  children: React.ReactNode;
  emitSorting?: (sorting?: SortingType<any>) => void;
}> = ({ children, emitSorting }) => {
  const [sorting, setSorting] = React.useState<SortingType<any>>();

  React.useEffect(() => {
    emitSorting?.(sorting);
  }, [sorting, emitSorting]);

  return (
    <TableContext.Provider value={{ sorting, setSorting, emitSorting }}>
      {children}
    </TableContext.Provider>
  );
};

const useTableSortContext = (): TableContextProps => {
  const context = React.useContext(TableContext);
  if (!context) {
    throw new Error('useSortContext must be used within a SortProvider');
  }
  return context;
};

type TableProps = { onChangeSorting?: (sorting?: SortingType<any>) => void };
const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & TableProps
>(({ className, onChangeSorting, ...props }, ref) => {
  return (
    <TableSortProvider emitSorting={onChangeSorting}>
      <div className="relative w-full overflow-auto">
        <table ref={ref} className={cn('w-full caption-bottom text-sm', className)} {...props} />
      </div>
    </TableSortProvider>
  );
});
Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn('sticky top-0 z-10 bg-background [&_tr]:border-none [&_th]:h-8', className)}
    {...props}
  />
));
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('[&_tr:last-child]:border-0 [&_tr:hover]:bg-muted/50 ', className)}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', className)}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn('border-b transition-colors data-[state=selected]:bg-muted', className)}
      {...props}
    />
  )
);
TableRow.displayName = 'TableRow';

type TableCellProps = { isNumeric?: boolean; sortable?: string };
const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & TableCellProps
>(({ className, children, isNumeric, sortable, ...props }, ref) => {
  const { sorting, setSorting } = useTableSortContext();

  const handleSortClick = React.useCallback(() => {
    if (sortable) {
      if (sorting?.key == sortable) {
        if (sorting.dir == 'asc') setSorting(undefined);
        if (sorting.dir == 'desc') setSorting({ key: sortable, dir: 'asc' });
      } else {
        setSorting({ key: sortable, dir: 'desc' });
      }
    }
  }, [sortable, sorting, setSorting]);

  const isActiveSorting = React.useMemo(
    () => sorting && sortable && sorting.key == sortable,
    [sorting, sortable]
  );

  const SortingIcon = React.useMemo(() => {
    if (!sortable) return null;
    if (!sorting || sorting.key !== sortable) return ArrowDownUp;
    if (sorting.dir == 'asc') return ArrowDownNarrowWide;
    if (sorting.dir == 'desc') return ArrowDownWideNarrow;
  }, [sortable, sorting]);

  return (
    <th
      ref={ref}
      className={cn(
        'h-12 px-4 text-left align-middle font-medium whitespace-nowrap text-third-foreground [&:has([role=checkbox])]:pr-0',
        className,
        { ['text-right']: isNumeric }
      )}
      {...props}
    >
      {sortable ? (
        <>
          <div className="flex items-center gap-1 justify-end">
            <span>{children}</span>
            {SortingIcon ? (
              <SortingIcon
                className={cn('size-4 cursor-pointer', {
                  ['text-secondary-foreground']: isActiveSorting,
                })}
                onClick={handleSortClick}
              />
            ) : null}
          </div>
        </>
      ) : (
        children
      )}
    </th>
  );
});
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & TableCellProps
>(({ className, isNumeric, ...props }, ref) => (
  <td
    ref={ref}
    className={cn('p-4 align-middle whitespace-nowrap [&:has([role=checkbox])]:pr-0', className, {
      ['text-right']: isNumeric,
    })}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
));
TableCaption.displayName = 'TableCaption';

export { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow };
