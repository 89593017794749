import { useMemo } from 'react';
import { ArrowUpNarrowWide, JapaneseYen } from 'lucide-react';

import { StrategyTypeEnum } from '@/types';

type StrategyTypeLabelProps = {
  type: StrategyTypeEnum;
  withText?: boolean;
  lighted?: boolean;
  size?: number;
};
export const StrategyTypeLabel = ({
  type,
  withText = true,
  lighted = true,
  size = 5,
}: StrategyTypeLabelProps) => {
  const color = useMemo(() => {
    if (!lighted) return 'text-gray';
    if (type == StrategyTypeEnum.Grid) return 'text-brand';
    if (type == StrategyTypeEnum.Kaeru) return 'text-blue';
  }, [type, lighted]);

  return (
    <div className={`flex items-center gap-1 ${color}`}>
      {withText ? <span className="text-sm">{type}</span> : null}
      {type == StrategyTypeEnum.Grid ? <ArrowUpNarrowWide className={`size-${size}`} /> : null}
      {type == StrategyTypeEnum.Kaeru ? <JapaneseYen className={`size-${size}`} /> : null}
    </div>
  );
};
