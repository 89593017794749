import { useMemo } from 'react';
import {
  ArrowUpDown,
  ChartCandlestick,
  CircleDollarSign,
  Coins,
  DollarSign,
  Gem,
  HandCoins,
  TrendingDown,
  TrendingUp,
} from 'lucide-react';

import { formatNumber, round } from '@/lib/common';
import { ReportResponse } from '@/types';

import { BalancesReportChart } from './BalancesReportChart';

type PortfolioReportChartProps = {
  aggregatedTransfers: Record<string, number>;
  netProfit: number;
  funding: number;
  fee: number;
  tradesStat: Record<string, number>;
  reportData?: ReportResponse;
  fromTs: number;
  toTs: number;
};
export const PortfolioReportChart = ({
  aggregatedTransfers,
  netProfit,
  funding,
  fee,
  tradesStat,
  reportData,
  fromTs,
  toTs,
}: PortfolioReportChartProps) => {
  const isReportLoading = useMemo(() => !reportData, [reportData]);

  const initialBalance = useMemo(() => reportData?.balances[0], [reportData]);

  const finalBalance = useMemo(
    () => reportData?.balances[reportData?.balances.length - 1],
    [reportData]
  );

  const marginBalanceDelta = useMemo(() => {
    if (initialBalance && finalBalance)
      return round(
        ((finalBalance.marginBalance - initialBalance.marginBalance) /
          initialBalance.marginBalance) *
          100,
        2
      );
    return null;
  }, [initialBalance, finalBalance]);

  const availableBalanceDelta = useMemo(() => {
    if (initialBalance && finalBalance)
      return round(
        ((finalBalance.availableBalance - initialBalance.availableBalance) /
          initialBalance.availableBalance) *
          100,
        2
      );
    return null;
  }, [initialBalance, finalBalance]);

  return (
    <div className="flex w-full gap-4">
      <div className="flex w-52 shrink-0 flex-col justify-start gap-4 border-r border-border">
        <PeriodParameter
          title={
            <div className="flex items-center">
              <DollarSign className="mr-1 size-5" /> Equity
            </div>
          }
          description={`Last: ${!isReportLoading ? formatNumber(initialBalance?.marginBalance || 0, 2) : '---'} $`}
          isIncrease={marginBalanceDelta ? marginBalanceDelta > 0 : undefined}
        >
          {marginBalanceDelta ? marginBalanceDelta : '---'}%
        </PeriodParameter>

        <PeriodParameter
          title={
            <div className="flex items-center">
              <CircleDollarSign className="mr-1 size-5" /> Available
            </div>
          }
          description={`Last: ${!isReportLoading ? formatNumber(initialBalance?.availableBalance || 0, 2) : '---'} $`}
          isIncrease={availableBalanceDelta ? availableBalanceDelta > 0 : undefined}
        >
          {availableBalanceDelta ? availableBalanceDelta : '---'}%
        </PeriodParameter>

        <PeriodParameter
          title={
            <div className="flex items-center">
              <ChartCandlestick className="mr-2 size-5" /> Trades
            </div>
          }
          description={`Gross: ${!isReportLoading ? formatNumber(tradesStat.operationProfit || 0, 2) : '---'} $`}
        >
          {!isReportLoading ? tradesStat.operationTrades : '---'}
        </PeriodParameter>

        <PeriodParameter
          title={
            <div className="flex items-center">
              <Gem className="mr-2 size-5" /> Net profit
            </div>
          }
          description="Gross - Commission"
        >
          {!isReportLoading ? formatNumber(netProfit, 2) : '---'} $
        </PeriodParameter>

        <PeriodParameter
          title={
            <div className="flex items-center">
              <HandCoins className="mr-2 size-5" /> Funding
            </div>
          }
          description={
            <div className="flex items-center">
              <Coins className="mr-1 size-4" /> Fees:
              {!isReportLoading ? formatNumber(fee, 2) : '---'} $
            </div>
          }
        >
          {!isReportLoading ? formatNumber(funding, 2) : '---'} $
        </PeriodParameter>

        <PeriodParameter
          title={
            <div className="flex items-center">
              <ArrowUpDown className="mr-2 size-5" /> Transfers
            </div>
          }
        >
          <div className="flex flex-col">
            {!isReportLoading && Object.entries(aggregatedTransfers).length
              ? Object.entries(aggregatedTransfers).map(([asset, income]) => (
                  <span key={asset}>
                    {income} {asset}
                  </span>
                ))
              : '0'}
          </div>
        </PeriodParameter>
      </div>

      <div className="relative flex min-w-[720px] grow flex-col">
        <BalancesReportChart reportData={reportData} fromTs={fromTs} toTs={toTs} />
      </div>
    </div>
  );
};

type PeriodParameterProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
  isIncrease?: boolean;
};
const PeriodParameter = ({ title, description, children, isIncrease }: PeriodParameterProps) => {
  return (
    <>
      <div className="flex flex-col p-2">
        <div className="text-base font-medium">{title}</div>
        <div className="flex items-center gap-2 py-1 text-2xl font-medium">
          {isIncrease !== undefined ? (
            isIncrease ? (
              <TrendingUp className="text-green" />
            ) : (
              <TrendingDown className="text-destructive" />
            )
          ) : null}
          {children}
        </div>
        {description ? <div className="text-sm text-muted-foreground">{description}</div> : null}
      </div>
    </>
  );
};
