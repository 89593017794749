import { useCallback, useMemo } from 'react';
import { Landmark } from 'lucide-react';
import { LabelList, Pie, PieChart } from 'recharts';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { usePositions } from '@/hooks/usePositions';
import { useTerminatedStrategies } from '@/hooks/useStrategies';
import { useActiveStrategies } from '@/hooks/useStrategies';
import { formatNumber, round } from '@/lib/common';
import { AnyStrategy, isGridStrategy } from '@/types';

export const MarginChartCard = () => {
  const {
    activeStrategies,
    activeStrategiesRequest: { isLoading: isActiveLoading },
  } = useActiveStrategies();
  const {
    terminatedStrategies,
    terminatedStrategiesRequest: { isLoading: isTerminatedLoading },
  } = useTerminatedStrategies();

  const isLoading = isActiveLoading || isTerminatedLoading;

  const strategies = useMemo(
    () => [...activeStrategies, ...terminatedStrategies],
    [activeStrategies, terminatedStrategies]
  );

  const totalMargin = useMemo(
    () => strategies.filter(isGridStrategy).reduce((acc, strategy) => acc + strategy.capital, 0),
    [strategies]
  );

  return (
    <Card className="min-w-[300px]">
      <CardHeader className="space-y-0 p-2">
        <CardTitle className="flex items-center text-lg font-medium text-secondary-foreground">
          <Landmark className="mr-2 size-5" /> Reserved Capital
        </CardTitle>
      </CardHeader>
      <CardContent className="px-4 pb-0 pt-2 text-2xl font-medium">
        <div>{!isLoading ? formatNumber(totalMargin, 2) : '---'} $</div>
        <div className="mt-2 flex h-[240px] flex-col">
          <MarginChart strategies={strategies} totalMargin={totalMargin} />
        </div>
      </CardContent>
      <CardFooter className="justify-center p-2 text-sm text-third-foreground">
        Capital of active and terminated
      </CardFooter>
    </Card>
  );
};

type ChartData = {
  symbol: string;
  value: number;
  capital: number;
  fill: string;
};
const chartConfig = {};

type MarginChartProps = {
  strategies: AnyStrategy[];
  totalMargin: number;
};
const MarginChart = ({ strategies, totalMargin }: MarginChartProps) => {
  const { getSymbolColor } = usePositions();

  const chartData = useMemo<ChartData[]>(
    () =>
      strategies.filter(isGridStrategy).map(strategy => {
        const percent = round((strategy.capital / totalMargin) * 100, 2);
        return {
          label: percent > 5 ? strategy.symbol.slice(0, -4) : '',
          symbol: strategy.symbol,
          value: percent,
          capital: strategy.capital,
          fill: getSymbolColor(strategy.symbol),
        };
      }),
    [strategies, totalMargin, getSymbolColor]
  );

  const tooltipValueFormatter = useCallback((val: number, item: any) => {
    return (
      <div className="ml-2 flex gap-2 font-mono font-medium tabular-nums">
        <span>{val}%</span>
        <span className="text-muted-foreground">{formatNumber(item.payload.capital, 2)}$</span>
      </div>
    );
  }, []);

  return (
    <ChartContainer
      config={chartConfig}
      className="w-full grow pb-0 [&_.recharts-pie-label-text]:fill-foreground"
    >
      <PieChart margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
        <ChartTooltip
          content={
            <ChartTooltipContent
              labelFormatter={() => 'Margin'}
              valueFormatter={tooltipValueFormatter}
            />
          }
        />
        <Pie data={chartData} dataKey="value" nameKey="symbol" label>
          <LabelList dataKey="label" className="pointer-events-none" fontSize={10} />
        </Pie>
      </PieChart>
    </ChartContainer>
  );
};
