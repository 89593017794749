import { useCallback, useMemo, useState } from 'react';
import { TokenIcon } from '@token-icons/react';
import {
  ArrowDown,
  ArrowUp,
  BookmarkX,
  CirclePlay,
  Ellipsis,
  Info,
  OctagonPause,
  Play,
  RefreshCcw,
  ShieldAlert,
} from 'lucide-react';

import { ConfirmStrategyRestart } from '@/components/blocks/modals/ConfirmStrategyRestart/ConfirmStrategyRestart';
import { StopStrategyModal } from '@/components/blocks/modals/StopStrategyModal/StopStrategyModal';
import { StrategyInfoModal } from '@/components/blocks/modals/StrategyInfoModal/StrategyInfoModal';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePositions } from '@/hooks/usePositions';
import { useStatus } from '@/hooks/useStatistic';
import {
  STRATEGY_REQUEST_UPDATE_DELAY,
  useKaeruStrategyActions,
  useStrategyActions,
  useTerminatedStrategies,
} from '@/hooks/useStrategies';
import {
  calculateLevels,
  formatDate,
  formatNumber,
  getStrategyAlivePeriod,
  round,
} from '@/lib/common';
import { cn } from '@/lib/utils';
import {
  AnyStrategy,
  GridStrategy,
  GridStrategyModeEnum,
  isGridStrategy,
  isKaeruStrategy,
  KaeruStrategy,
  KaeruStrategyModeEnum,
  PositionSideEnum,
  StrategyStateEnum,
  StrategyStopModeEnum,
} from '@/types';

import { ConfirmCloseKaeruTrade } from '../modals/ConfirmCloseKaeruTrade/ConfirmCloseKaeruTrade';
import { StartStrategyModal } from '../modals/StartStrategyModal/StartStrategyModal';
import { StrategyStatusIcon } from '../StrategyStatusIcon/StrategyStatusIcon';
import { StrategyTypeLabel } from '../StrategyStatusIcon/StrategyTypeLabel';

type StrategyProps = {
  strategy: AnyStrategy;
  withActions: boolean;
  onStartCopy?: () => void;
  onSymbolClick?: () => void;
};

export const StrategyItem = ({
  strategy,
  onStartCopy,
  onSymbolClick,
  withActions = true,
}: StrategyProps) => {
  const { restartStrategiesMutation, stopStrategyMutation } = useStrategyActions();
  const { terminatedStrategies } = useTerminatedStrategies();
  const { manuallyCloseKaeruTradeMutation } = useKaeruStrategyActions();

  const {
    isOpen: isOpenAllRestart,
    onOpen: onOpenAllRestart,
    onClose: onCloseAllRestart,
  } = useDisclosure();
  const { isOpen: isOpenInfo, onOpen: onOpenInfo, onClose: onCloseInfo } = useDisclosure();
  const { isOpen: isOpenRestart, onOpen: onOpenRestart, onClose: onCloseRestart } = useDisclosure();
  const { isOpen: isOpenStop, onOpen: onOpenStop, onClose: onCloseStop } = useDisclosure();
  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure();
  const {
    isOpen: isOpenCloseTrade,
    onOpen: onOpenCloseTrade,
    onClose: onCloseCloseTrade,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const isActive = useMemo(() => strategy.state == StrategyStateEnum.Active, [strategy.state]);
  const isStopped = useMemo(() => strategy.state == StrategyStateEnum.Stopped, [strategy.state]);
  const isTerminated = useMemo(
    () => strategy.state == StrategyStateEnum.Terminated,
    [strategy.state]
  );

  const terminatedIds = useMemo(
    () => terminatedStrategies.map(ts => ts.id),
    [terminatedStrategies]
  );

  const handleStartCopy = useCallback(() => onStartCopy?.(), [onStartCopy]);
  const handleStop = useCallback(
    (stopMode: StrategyStopModeEnum) => {
      setIsLoading(true);
      stopStrategyMutation
        .mutateAsync({ id: strategy.id, stopMode })
        .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
        .catch(() => setIsLoading(false))
        .finally(() => onCloseStop());
    },
    [strategy.id, stopStrategyMutation, onCloseStop]
  );
  const handleRestart = useCallback(() => {
    setIsLoading(true);
    restartStrategiesMutation
      .mutateAsync([strategy.id])
      .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
      .catch(() => setIsLoading(false))
      .finally(() => onCloseRestart());
  }, [strategy.id, restartStrategiesMutation, onCloseRestart]);
  const handleRestartAll = useCallback(() => {
    setIsLoading(true);
    restartStrategiesMutation
      .mutateAsync(terminatedIds)
      .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
      .catch(() => setIsLoading(false))
      .finally(() => onCloseAllRestart());
  }, [restartStrategiesMutation, onCloseAllRestart, terminatedIds]);

  const onManuallyCloseKaeruTrade = useCallback(() => {
    setIsLoading(true);
    manuallyCloseKaeruTradeMutation
      .mutateAsync(strategy.id)
      .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
      .catch(() => setIsLoading(false))
      .finally(() => onCloseCloseTrade());
  }, [manuallyCloseKaeruTradeMutation, strategy.id, onCloseCloseTrade]);

  return (
    <div className="relative min-w-[420px] border-b border-border p-3 last:border-none">
      {isLoading ? <CenteredSpinner /> : null}

      {isGridStrategy(strategy) ? (
        <GridStrategyItem {...strategy} onSymbolClick={onSymbolClick} />
      ) : null}
      {isKaeruStrategy(strategy) ? (
        <KaeruStrategyItem {...strategy} onSymbolClick={onSymbolClick} />
      ) : null}

      <div className="absolute right-2 top-2 flex items-center gap-1">
        <Button size="icon" variant="ghost" className="size-8" onClick={onOpenInfo}>
          <Info className="size-4" />
        </Button>

        {withActions ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost" className="size-8">
                <Ellipsis className="size-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="whitespace-nowrap" align="end">
              {!isStopped ? (
                <>
                  <DropdownMenuItem onClick={onOpenStop}>
                    <OctagonPause className="mr-2 size-4" />
                    <span>Stop strategy</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={onOpenUpdate}>
                    <ShieldAlert className="mr-2 size-4" />
                    <span>Update strategy</span>
                  </DropdownMenuItem>
                </>
              ) : null}
              {isTerminated ? (
                <>
                  <DropdownMenuItem onClick={onOpenRestart}>
                    <Play className="mr-2 size-4" />
                    <span>Restart this strategy</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={onOpenAllRestart}>
                    <CirclePlay className="mr-2 size-4" />
                    <span>Restart all strategies</span>
                  </DropdownMenuItem>
                </>
              ) : null}
              {isActive ? (
                <>
                  {isKaeruStrategy(strategy) && strategy.inTradeNow ? (
                    <DropdownMenuItem onClick={onOpenCloseTrade}>
                      <BookmarkX className="mr-2 size-4" />
                      <span>Close trade manually</span>
                    </DropdownMenuItem>
                  ) : null}
                </>
              ) : (
                <>
                  <DropdownMenuItem onClick={handleStartCopy}>
                    <RefreshCcw className="mr-2 size-4" />
                    <span>Start with same params</span>
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        ) : null}

        <StrategyInfoModal isOpen={isOpenInfo} strategyId={strategy.id} onClose={onCloseInfo} />
        {isOpenAllRestart ? (
          <ConfirmStrategyRestart isAll onCancel={onCloseAllRestart} onConfirm={handleRestartAll} />
        ) : null}
        {isOpenRestart ? (
          <ConfirmStrategyRestart
            id={strategy.id}
            symbol={strategy.symbol}
            onCancel={onCloseRestart}
            onConfirm={handleRestart}
          />
        ) : null}
        {isOpenStop ? (
          <StopStrategyModal
            id={strategy.id}
            symbol={strategy.symbol}
            type={strategy.type}
            state={strategy.state}
            onClose={onCloseStop}
            onConfirm={handleStop}
          />
        ) : null}
        {isOpenCloseTrade ? (
          <ConfirmCloseKaeruTrade
            strategyId={strategy.id}
            onCancel={onCloseCloseTrade}
            onConfirm={onManuallyCloseKaeruTrade}
          />
        ) : null}
        {isOpenUpdate ? (
          <StartStrategyModal isEdit={true} params={strategy} onClose={onCloseUpdate} />
        ) : null}
      </div>
    </div>
  );
};

type GridStrategyItemProps = GridStrategy & {
  onSymbolClick?: () => void;
};
const GridStrategyItem = ({
  type,
  symbol,
  operationProfit,
  totalFees,
  tradeBalance,
  capital,
  startTimestamp,
  stopTimestamp,
  minPrice,
  maxPrice,
  mode,
  id,
  state,
  hedgeEnabled,
  hedgeGap,
  operationTrades,
  step,
  gridLevel,
  tradeSize,
  onSymbolClick,
}: GridStrategyItemProps) => {
  const { positionsMap } = usePositions();
  const { commissionsStat } = useStatus();

  const currentPosition = useMemo(() => {
    if (positionsMap[symbol]) {
      // Считаем только позицию относящуюся к стратегии
      const levels = calculateLevels(minPrice, maxPrice, step);
      const investPosition = (levels.length - 1 - gridLevel) * tradeSize;
      const longPosition = positionsMap[symbol].find(p => p.positionSide == PositionSideEnum.LONG);

      if (longPosition) {
        const strategyPositionCoeff = Math.abs(investPosition) / Math.abs(longPosition.positionAmt);
        return {
          ...longPosition,
          notional: longPosition.notional * strategyPositionCoeff,
          positionAmt: investPosition,
          unRealizedProfit: longPosition.notional * strategyPositionCoeff,
        };
      }
    }
    return undefined;
  }, [positionsMap, symbol, minPrice, maxPrice, step, gridLevel, tradeSize]);

  const netProfit = operationProfit + totalFees;
  const netProfitPercent = round((netProfit / capital) * 100, 2);
  const tradeBalancePercent = round(((tradeBalance || 0) / capital) * 100, 2);
  const startDate = formatDate(new Date(startTimestamp), { withTime: false, withYear: true });
  const assetSymbol = symbol.slice(0, -4).toUpperCase();

  const alive = useMemo(
    () => getStrategyAlivePeriod({ state, startTimestamp, stopTimestamp }),
    [startTimestamp, stopTimestamp, state]
  );

  const modeColor = useMemo(() => {
    if (mode == GridStrategyModeEnum.stopOnTop) return 'bg-blue';
    if (mode === GridStrategyModeEnum.reduceEntryPrice) return 'bg-yellow';
    return 'bg-green';
  }, [mode]);

  const currentPriceIcon = useMemo(() => {
    if (currentPosition) {
      const iconClasses = 'text-red size-5 mr-1';
      if (currentPosition.markPrice < minPrice) return <ArrowDown className={iconClasses} />;
      if (currentPosition.markPrice > maxPrice) return <ArrowUp className={iconClasses} />;
    }
    return '';
  }, [currentPosition, minPrice, maxPrice]);

  const totalFunding = useMemo(
    () => commissionsStat.find(cs => cs.symbol == symbol)?.totalFunding || 0,
    [commissionsStat, symbol]
  );

  const handleSymbolClick = useCallback(() => onSymbolClick?.(), [onSymbolClick]);

  const dash = <p className="mx-3">|</p>;

  return (
    <div className="relative flex items-start">
      <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-third">
        <TokenIcon symbol={assetSymbol} size="32" variant="branded" />
      </div>

      <div className="ml-3">
        <div className="mb-1 flex items-center">
          <div className="mr-2">
            <StrategyStatusIcon state={state} />
          </div>
          <span
            className={cn('mr-2 font-medium', { ['cursor-pointer']: onSymbolClick })}
            onClick={handleSymbolClick}
          >
            {symbol} ({id})
          </span>

          <StrategyTypeLabel type={type} />

          <div className="relative ml-2 px-2 py-1 text-xs">
            <div className={cn('absolute inset-0 rounded-full opacity-50', modeColor)}></div>
            <div className="relative">{mode.replaceAll('_', ' ')}</div>
          </div>
        </div>

        <div className="text-md flex items-center">
          <span className="whitespace-nowrap">
            {formatNumber(minPrice, 'full')}$ - {formatNumber(maxPrice, 'full')}$
          </span>
          {dash}
          <span>{round(step * 100, 2)}%</span>
          {dash}
          <span>{formatNumber(capital, 2)}$</span>
        </div>

        <div className="mb-2 flex items-center whitespace-nowrap text-sm text-secondary-foreground">
          <span className="inline-flex items-center">
            {currentPosition ? (
              <>
                {currentPriceIcon} {formatNumber(currentPosition.markPrice)}
              </>
            ) : (
              '--'
            )}
            $
          </span>
          {dash}
          <span>{alive}</span>
          {dash}
          <span>{startDate}</span>
        </div>

        <div className="flex flex-col">
          {hedgeEnabled ? (
            <StrategyFieldValue label="Hedge:">
              <div className="flex items-center">
                <ShieldAlert className="mr-1 size-4 text-yellow" /> enabled {(hedgeGap || 0) * 100}%
                ({formatNumber(minPrice * (1 - (hedgeGap || 0)))}$)
              </div>
            </StrategyFieldValue>
          ) : null}
          <StrategyFieldValue label="Trades:">
            {operationTrades} ({formatNumber(operationProfit, 2)}$)
          </StrategyFieldValue>
          <StrategyFieldValue label="Net profit:">
            {formatNumber(netProfit, 2)}$ ({netProfitPercent}%)
          </StrategyFieldValue>
          <StrategyFieldValue label="Funding:">{formatNumber(totalFunding, 2)}$</StrategyFieldValue>
          {state == StrategyStateEnum.Active ? (
            <StrategyFieldValue label="Position:">
              {currentPosition
                ? `${formatNumber(currentPosition.notional, 2)}$ (${formatNumber(currentPosition.positionAmt)})`
                : '--'}
            </StrategyFieldValue>
          ) : null}
          <StrategyFieldValue label="Strategy TB:">
            {formatNumber(tradeBalance || 0, 2)}$ ({tradeBalancePercent}%)
          </StrategyFieldValue>
        </div>
      </div>
    </div>
  );
};

type KaeruStrategyItemProps = KaeruStrategy & {
  onSymbolClick?: () => void;
};
const KaeruStrategyItem = ({
  id,
  type,
  startTimestamp,
  stopTimestamp,
  mode,
  symbol,
  tradeBalance,
  tradeSize,
  rsiLength,
  smaLength,
  timeframe,
  operationProfit,
  currentPnl,
  inTradeNow,
  operationTrades,
  state,
  onSymbolClick,
}: KaeruStrategyItemProps) => {
  const startDate = formatDate(new Date(startTimestamp), { withTime: false, withYear: true });
  const assetSymbol = symbol.slice(0, -4).toUpperCase();

  const alive = useMemo(
    () => getStrategyAlivePeriod({ state, startTimestamp, stopTimestamp }),
    [state, startTimestamp, stopTimestamp]
  );

  const modeColor = useMemo(() => {
    if (mode === KaeruStrategyModeEnum.Short) return 'bg-red';
    return 'bg-green';
  }, [mode]);

  const handleSymbolClick = useCallback(() => onSymbolClick?.(), [onSymbolClick]);
  const dash = <p className="mx-3">|</p>;

  return (
    <div className="relative flex items-start">
      <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-third">
        <TokenIcon symbol={assetSymbol} size="32" variant="branded" />
      </div>

      <div className="ml-3">
        <div className="mb-1 flex items-center">
          <div className="mr-2">
            <StrategyStatusIcon state={state} />
          </div>

          <span
            className={cn('mr-2 font-medium', { ['cursor-pointer']: onSymbolClick })}
            onClick={handleSymbolClick}
          >
            {symbol} ({id})
          </span>

          <StrategyTypeLabel type={type} />

          <div className="relative ml-2 px-2 py-1 text-xs">
            <div className={cn('absolute inset-0 rounded-full opacity-50', modeColor)}></div>
            <div className="relative">{mode.replaceAll('_', ' ')}</div>
          </div>
        </div>

        <div className="mb-1 flex items-center whitespace-nowrap text-sm text-secondary-foreground">
          <span className="mr-2">RSI: {rsiLength}</span>

          <span>SMA: {smaLength}</span>
          {dash}
          <span>{alive}</span>
          {dash}
          <span>{startDate}</span>
        </div>

        <div className="flex flex-col">
          <StrategyFieldValue label="Timeframe:">{timeframe}</StrategyFieldValue>
          <StrategyFieldValue label="Trade size:">
            {tradeSize} {symbol}
          </StrategyFieldValue>
          <StrategyFieldValue label="Trades:">
            {operationTrades} ({formatNumber(operationProfit, 2)}$)
          </StrategyFieldValue>
          {state == StrategyStateEnum.Active ? (
            <StrategyFieldValue label="In trade now:">
              {inTradeNow ? <>Yes ({formatNumber(currentPnl || 0, 2)}$)</> : 'No'}
            </StrategyFieldValue>
          ) : null}
          <StrategyFieldValue label="Strategy TB:">
            {formatNumber(tradeBalance || 0, 2)}$
          </StrategyFieldValue>
        </div>
      </div>
    </div>
  );
};

type StrategyFieldValueProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const StrategyFieldValue = ({ label, value, children }: StrategyFieldValueProps) => {
  return (
    <div className="flex items-center whitespace-nowrap text-sm">
      <p className="w-28 shrink-0 text-secondary-foreground">{label}</p>
      <p className="font-medium">{children || value}</p>
    </div>
  );
};
