import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown } from 'lucide-react';

import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useAllStrategies } from '@/hooks/useStrategies';
import { formatDate, formatNumber, getStrategyAlivePeriod, round } from '@/lib/common';
import { arraysEqual, cn } from '@/lib/utils';
import { AnyStrategy, isGridStrategy, isKaeruStrategy, StrategyStateEnum } from '@/types';

type StrategiesSelectProps = {
  selected?: AnyStrategy[];
  onSelect: (strategies: AnyStrategy[]) => void;
};

export const StrategiesSelect = ({ onSelect, selected }: StrategiesSelectProps) => {
  const { allStrategies } = useAllStrategies();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStrategies, setSelectedStrategies] = useState<number[]>([]);
  const [search] = useState('');
  const isInit = useRef(false);

  useEffect(() => {
    setSelectedStrategies((selected || []).map(s => s.id));
  }, [selected]);

  const activeStrategiesIds = useMemo<number[]>(
    () =>
      allStrategies.filter(strategy => strategy.state == StrategyStateEnum.Active).map(s => s.id),
    [allStrategies]
  );
  const isAllSelected = useMemo(
    () =>
      arraysEqual(
        allStrategies.map(s => s.id),
        selectedStrategies
      ),
    [selectedStrategies, allStrategies]
  );
  const isActiveSelected = useMemo(
    () => arraysEqual(activeStrategiesIds, selectedStrategies),
    [activeStrategiesIds, selectedStrategies]
  );

  const handleSelectStrategies = useCallback(
    (ids: number[]) => {
      setSelectedStrategies(ids);
      const strategies = allStrategies.filter(s => ids.includes(s.id));
      onSelect(strategies);
    },
    [allStrategies, onSelect]
  );

  useEffect(() => {
    if (!isInit.current && activeStrategiesIds.length) {
      handleSelectStrategies(activeStrategiesIds);
      isInit.current = true;
    }
  }, [activeStrategiesIds, handleSelectStrategies]);

  const toggleActiveSelected = useCallback(
    (checked: boolean) => {
      if (checked) {
        handleSelectStrategies(activeStrategiesIds);
      } else {
        handleSelectStrategies([]);
      }
    },
    [activeStrategiesIds, handleSelectStrategies]
  );
  const toggleAllSelected = useCallback(
    (checked: boolean) => {
      if (checked) {
        handleSelectStrategies(allStrategies.map(s => s.id));
      } else {
        handleSelectStrategies([]);
      }
    },
    [allStrategies, handleSelectStrategies]
  );

  const filteredStrategies = useMemo(
    () =>
      allStrategies
        .filter(
          strategy =>
            !search ||
            strategy.id.toString().includes(search) ||
            strategy.symbol.toLowerCase().includes(search.toLowerCase())
        )
        .sort((a, b) => (a.symbol > b.symbol ? 1 : b.symbol > a.symbol ? -1 : 0)),
    [allStrategies, search]
  );

  const symbolsWithStrategies = useMemo(
    () =>
      filteredStrategies.reduce(
        (acc, strategy) => {
          if (strategy.symbol in acc) acc[strategy.symbol].push(strategy);
          else acc[strategy.symbol] = [strategy];

          return acc;
        },
        {} as Record<string, AnyStrategy[]>
      ),
    [filteredStrategies]
  );

  const symbolsWithSelectedCount = useMemo(
    () =>
      Object.entries(symbolsWithStrategies)
        .map(([symbol, strategies]) => ({
          symbol,
          count: strategies.reduce(
            (acc, strategy) => (selectedStrategies.includes(strategy.id) ? acc + 1 : acc),
            0
          ),
        }))
        .filter(({ count }) => Boolean(count)),
    [symbolsWithStrategies, selectedStrategies]
  );

  return (
    <DropdownMenu open={isOpen} onOpenChange={onClose}>
      <DropdownMenuTrigger asChild>
        <div
          className="flex max-h-[40px] max-w-[340px] cursor-pointer flex-wrap items-center gap-1 self-baseline overflow-auto rounded-md border border-border p-1 text-xs"
          onClick={onOpen}
        >
          {!symbolsWithSelectedCount.length ? (
            <div className="mx-2 text-third-foreground">Select strategies...</div>
          ) : null}
          {symbolsWithSelectedCount.map(({ symbol, count }, index) => (
            <div key={index} className="whitespace-nowrap rounded-lg bg-third p-1 px-2">
              {symbol} ({count})
            </div>
          ))}

          <ChevronDown className="ml-auto text-secondary-foreground" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[400px] w-56 overflow-auto" align="end">
        <DropdownMenuCheckboxItem
          checked={isActiveSelected}
          onCheckedChange={toggleActiveSelected}
          onSelect={e => e.preventDefault()}
        >
          Active Strategies ({activeStrategiesIds.length})
        </DropdownMenuCheckboxItem>

        <DropdownMenuCheckboxItem
          checked={isAllSelected}
          onCheckedChange={toggleAllSelected}
          onSelect={e => e.preventDefault()}
        >
          All Strategies ({allStrategies.length})
        </DropdownMenuCheckboxItem>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          {Object.entries(symbolsWithStrategies).map(([symbol, strategies]) => (
            <SymbolSubSelect
              key={symbol}
              symbol={symbol}
              strategies={strategies}
              selectedIds={selectedStrategies}
              onSelect={handleSelectStrategies}
            />
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

type SymbolSubSelectProps = {
  symbol: string;
  strategies: AnyStrategy[];
  selectedIds: number[];
  onSelect: (ids: number[]) => void;
};
const SymbolSubSelect = ({ symbol, strategies, selectedIds, onSelect }: SymbolSubSelectProps) => {
  const isFullySelected = useMemo(
    () => strategies.every(strategy => selectedIds.includes(strategy.id)),
    [selectedIds, strategies]
  );
  const isPartlySelected = useMemo(
    () => strategies.some(strategy => selectedIds.includes(strategy.id)),
    [selectedIds, strategies]
  );

  const toggleSelectedState = useCallback(() => {
    // nonSelected -> allSelected
    // partlySelected -> allSelected
    // allSelected -> nonSelected
    const strategiesIds = strategies.map(s => s.id);
    if (isFullySelected) {
      onSelect(selectedIds.filter(id => !strategiesIds.includes(id)));
      return;
    }
    onSelect([...selectedIds, ...strategiesIds]);
  }, [strategies, selectedIds, onSelect, isFullySelected]);

  const toggleStrategySelect = useCallback(
    (strategyId: number, checked: boolean) => {
      if (checked) {
        onSelect([...selectedIds, strategyId]);
      } else {
        onSelect(selectedIds.filter(id => id !== strategyId));
      }
    },
    [onSelect, selectedIds]
  );

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <Checkbox
          color={!isFullySelected ? 'gray' : undefined}
          checked={isPartlySelected}
          onCheckedChange={toggleSelectedState}
        />
        <span className="pointer-events-none ml-2">{symbol}</span>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent
          onFocusOutside={(e: any) => {
            const component = e.target.getAttribute('data-component');
            return component == 'checkbox' ? e.preventDefault() : undefined;
          }}
        >
          {strategies
            .sort((a, b) => b.id - a.id)
            .map(strategy => (
              <DropdownMenuCheckboxItem
                key={strategy.id}
                checked={selectedIds.includes(strategy.id)}
                onCheckedChange={checked => toggleStrategySelect(strategy.id, checked)}
                onSelect={e => e.preventDefault()}
              >
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <div>ID: {strategy.id}</div>
                    <div
                      className={cn(`ml-2 size-2 rounded-full`, {
                        'bg-yellow': strategy.state == StrategyStateEnum.Terminated,
                        'bg-green': strategy.state == StrategyStateEnum.Active,
                        'bg-red': strategy.state == StrategyStateEnum.Stopped,
                      })}
                    />

                    <div className="ml-2 text-third-foreground">
                      {formatDate(new Date(strategy.startTimestamp))}
                      <span className="ml-1">({getStrategyAlivePeriod(strategy)})</span>
                    </div>
                  </div>
                  {isGridStrategy(strategy) ? (
                    <div className="text-xs text-third-foreground">
                      GRID: {formatNumber(strategy.minPrice)}$ - {formatNumber(strategy.maxPrice)}$
                      | {round(strategy.step * 100, 2)}% | {formatNumber(strategy.capital, 2)}$
                    </div>
                  ) : null}
                  {isKaeruStrategy(strategy) ? (
                    <div className="text-xs text-third-foreground">
                      KAERU: TF: {strategy.timeframe} RSI: {strategy.rsiLength} SMA:{' '}
                      {strategy.smaLength}
                    </div>
                  ) : null}
                </div>
              </DropdownMenuCheckboxItem>
            ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
