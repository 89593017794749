import { useCallback, useMemo } from 'react';
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';

import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { usePositions } from '@/hooks/usePositions';
import { formatDate, formatNumber } from '@/lib/common';

type ChartData = {
  date: Date;
  endDate: Date;
  values: Record<string, number>;
};
type SymbolsParameterChartProps = {
  mode: 'aggregate' | 'absolute';
  symbols: string[];
  data: ChartData[];
  isCurrency?: boolean;
  label: string;
};
export const SymbolsParameterChart = ({
  mode = 'aggregate',
  symbols,
  data,
  isCurrency,
  label,
}: SymbolsParameterChartProps) => {
  const { getSymbolColor } = usePositions();

  const chartData = useMemo(() => {
    if (mode === 'aggregate') {
      const resData = [];

      for (const d of data) {
        const values: Record<string, number> =
          resData.length > 0
            ? { ...resData[resData.length - 1].values }
            : Object.fromEntries(symbols.map(s => [s, 0]));

        for (const symbol in d.values) {
          values[symbol] += d.values[symbol];
        }

        resData.push({ ...d, values });
      }

      return resData;
    } else {
      return data.map(d => ({
        ...d,
        values: { ...Object.fromEntries(symbols.map(s => [s, 0])), ...d.values },
      }));
    }
  }, [data, symbols, mode]);

  const historyChartConfig = useMemo(
    () =>
      symbols.reduce(
        (acc, symbol) => {
          acc[symbol] = { color: getSymbolColor(symbol), label: symbol };
          return acc;
        },
        {} as Record<string, { color: string; label: string }>
      ),
    [symbols, getSymbolColor]
  );

  const tooltipValueFormatter = useCallback(
    (val: any) => {
      return (
        <span className="ml-2">
          {formatNumber(val, isCurrency ? 2 : 'full') + `${isCurrency ? '$' : ''}`}
        </span>
      );
    },
    [isCurrency]
  );

  return (
    <ChartContainer config={historyChartConfig}>
      <LineChart data={chartData}>
        <CartesianGrid vertical={true} />
        <XAxis
          dataKey="date"
          interval="equidistantPreserveStart"
          tickLine={false}
          axisLine={false}
          tickMargin={10}
          tickFormatter={value => formatDate(new Date(value))}
        />
        <YAxis tickLine={false} axisLine={false} mirror orientation="left" />

        <ChartTooltip
          content={
            <ChartTooltipContent
              labelFormatter={(_, payload) => {
                if (payload.length) {
                  const date = payload[0].payload.date;
                  return formatDate(date);
                }
              }}
              valueFormatter={tooltipValueFormatter}
            />
          }
        />
        <ChartLegend content={<ChartLegendContent />} />

        {symbols.map(symbol => (
          <Line
            key={`history-${label}-${symbol}`}
            dataKey={`values[${symbol}]`}
            name={symbol}
            label={symbol}
            type="monotone"
            stroke={`var(--color-${symbol})`}
            strokeWidth={2}
            dot={false}
          />
        ))}
      </LineChart>
    </ChartContainer>
  );
};
