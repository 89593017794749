import { useMemo } from 'react';

import {
  GridStrategyInfoFields,
  KaeruStrategyInfoFields,
  StrategyInfoFieldValue,
} from '@/components/blocks/StrategyInfoFields/StrategyInfoFields';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useStrategy } from '@/hooks/useStrategies';
import { formatDate, formatNumber, getStrategyAlivePeriod } from '@/lib/common';
import {
  GridStrategy,
  GridStrategyCalculatedParams,
  isGridCalculatedParams,
  isGridStrategy,
  isKaeruCalculatedParams,
  isKaeruStrategy,
  KaeruStrategy,
  KaeruStrategyCalculatedParams,
  StrategyStateEnum,
} from '@/types';

import { StrategyStatusIcon } from '../../StrategyStatusIcon/StrategyStatusIcon';
import { StrategyTypeLabel } from '../../StrategyStatusIcon/StrategyTypeLabel';

type StrategyInfoModalProps = { isOpen: boolean; strategyId?: number; onClose: () => void };
export const StrategyInfoModal = ({ isOpen, strategyId, onClose }: StrategyInfoModalProps) => {
  const {
    strategyStartParams,
    strategyWorkingStat,
    strategyInfoRequest: { isLoading },
  } = useStrategy(isOpen ? strategyId : undefined);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        aria-describedby="Strategy info dialog"
        className="flex max-h-[90vh] min-h-[400px] w-fit min-w-[600px] max-w-fit flex-col"
      >
        <DialogHeader>
          <DialogTitle>Strategy info</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        {isLoading ? <CenteredSpinner /> : null}

        {strategyStartParams &&
        strategyWorkingStat &&
        isGridStrategy(strategyWorkingStat) &&
        isGridCalculatedParams(strategyStartParams) ? (
          <GridInfo strategyStat={strategyWorkingStat} startParams={strategyStartParams} />
        ) : null}

        {strategyStartParams &&
        strategyWorkingStat &&
        isKaeruStrategy(strategyWorkingStat) &&
        isKaeruCalculatedParams(strategyStartParams) ? (
          <KaeruInfo strategyStat={strategyWorkingStat} startParams={strategyStartParams} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

type GridInfoProps = {
  strategyStat: GridStrategy;
  startParams: GridStrategyCalculatedParams;
};
const GridInfo = ({ strategyStat, startParams }: GridInfoProps) => {
  const averageProfit = useMemo(
    () => strategyStat.operationProfit / strategyStat.operationTrades,
    [strategyStat]
  );
  const averageFee = useMemo(
    () => strategyStat.totalFees / strategyStat.operationTrades,
    [strategyStat]
  );

  return (
    <div className="flex gap-4">
      <div className="flex w-[300px] flex-col gap-1">
        <p className="text-subheading text-center">Working params</p>
        <StrategyInfoFieldValue label="Strategy id:">{strategyStat.id}</StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Symbol:">{strategyStat.symbol}</StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Type:">
          <StrategyTypeLabel type={strategyStat.type} />
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Status:">
          <div className="flex items-center gap-1">
            <StrategyStatusIcon state={strategyStat.state} />
            {strategyStat.state}
          </div>
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Current level:">
          {strategyStat.gridLevel} (
          {startParams ? startParams.levels[strategyStat.gridLevel] : '---'}
          $)
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Strategy start price:">
          {formatNumber(strategyStat.strategyStartPrice)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Initial position price:">
          {formatNumber(strategyStat.initialPositionPrice)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Started at:">
          {formatDate(new Date(strategyStat.startTimestamp), { withYear: true })}
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Alive:">
          {getStrategyAlivePeriod(strategyStat)}
        </StrategyInfoFieldValue>

        {strategyStat.state != StrategyStateEnum.Active ? (
          <StrategyInfoFieldValue label="Stopped at:">
            {formatDate(new Date(strategyStat.stopTimestamp || 0), { withYear: true })}
          </StrategyInfoFieldValue>
        ) : null}

        <p className="text-subheading">STATISTIC</p>

        <StrategyInfoFieldValue label="Strategy TB:">
          {formatNumber(strategyStat.tradeBalance || 0, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Net profit:">
          {formatNumber(strategyStat.operationProfit + strategyStat.totalFees, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Op. trades:">
          {strategyStat.operationTrades} ({formatNumber(strategyStat.operationProfit, 2)} $)
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Avg. trade profit:">
          {formatNumber(averageProfit, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Avg. trade fee:">
          {formatNumber(averageFee, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Current PNL:">
          {formatNumber(strategyStat.currentPnl || 0, 2)} $
        </StrategyInfoFieldValue>
      </div>
      <div className="flex w-[350px] flex-col">
        <p className="text-subheading text-center">Strategy params</p>
        <GridStrategyInfoFields {...startParams} />
      </div>
    </div>
  );
};
type KaeruInfoProps = {
  strategyStat: KaeruStrategy;
  startParams: KaeruStrategyCalculatedParams;
};
const KaeruInfo = ({ strategyStat, startParams }: KaeruInfoProps) => {
  const averageProfit = useMemo(
    () => strategyStat.operationProfit / strategyStat.operationTrades,
    [strategyStat]
  );
  const averageFee = useMemo(
    () => strategyStat.totalFees / strategyStat.operationTrades,
    [strategyStat]
  );

  return (
    <div className="flex gap-4">
      <div className="flex w-[300px] flex-col gap-1">
        <p className="text-subheading text-center">Working params</p>
        <StrategyInfoFieldValue label="Strategy id:">{strategyStat.id}</StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Symbol:">{strategyStat.symbol}</StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Type:">
          <StrategyTypeLabel type={strategyStat.type} />
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Status:">
          <div className="flex items-center gap-1">
            <StrategyStatusIcon state={strategyStat.state} />
            {strategyStat.state}
          </div>
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Started at:">
          {formatDate(new Date(strategyStat.startTimestamp), { withYear: true })}
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Alive:">
          {getStrategyAlivePeriod(strategyStat)}
        </StrategyInfoFieldValue>

        {strategyStat.state != StrategyStateEnum.Active ? (
          <StrategyInfoFieldValue label="Stopped at:">
            {formatDate(new Date(strategyStat.stopTimestamp || 0), { withYear: true })}
          </StrategyInfoFieldValue>
        ) : null}

        <p className="text-subheading">STATISTIC</p>
        <StrategyInfoFieldValue label="Strategy TB:">
          {formatNumber(strategyStat.tradeBalance || 0, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Net profit:">
          {formatNumber(strategyStat.operationProfit + strategyStat.totalFees, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Op. trades:">
          {strategyStat.operationTrades} ({formatNumber(strategyStat.operationProfit, 2)} $)
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Avg. trade profit:">
          {formatNumber(averageProfit, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="Avg. trade fee:">
          {formatNumber(averageFee, 2)} $
        </StrategyInfoFieldValue>

        <StrategyInfoFieldValue label="In trade now:">
          {strategyStat.inTradeNow ? (
            <>Yes (PNL: {formatNumber(strategyStat.currentPnl || 0, 2)}$)</>
          ) : (
            'No'
          )}
        </StrategyInfoFieldValue>

        <p className="text-subheading">LAST TRADE</p>
        {strategyStat.lastTrade ? (
          <>
            <StrategyInfoFieldValue label="Type:">
              {strategyStat.lastTrade?.positionSide} - {strategyStat.lastTrade?.side}
            </StrategyInfoFieldValue>
            <StrategyInfoFieldValue label="Executed at:">
              {formatDate(new Date(strategyStat.lastTrade?.serverTimestamp))}
            </StrategyInfoFieldValue>
            <StrategyInfoFieldValue label="Order price:">
              {formatNumber(strategyStat.lastTrade?.avgPrice, 'full')}
            </StrategyInfoFieldValue>
            <StrategyInfoFieldValue label="Op. profit:">
              {strategyStat.lastTrade.operationProfit
                ? formatNumber(strategyStat.lastTrade.operationProfit, 2) + ' $'
                : '---'}
            </StrategyInfoFieldValue>
          </>
        ) : (
          <StrategyInfoFieldValue label="Trade:">No trades</StrategyInfoFieldValue>
        )}
      </div>

      <div className="flex w-[350px] flex-col">
        <p className="text-subheading text-center">Strategy params</p>
        <KaeruStrategyInfoFields {...startParams} />
      </div>
    </div>
  );
};
