import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/AuthContext';
import { BASIC_GENERATION_COLOR, generateColorSet } from '@/lib/colors';
import { round } from '@/lib/common';
import {
  getAccount,
  getAdlQuantile,
  getAssetIndex,
  getBalance,
  getKlines,
  getPositions,
  getTransfers,
  setLeverage,
  setTransferEntryPrice,
} from '@/modules/api';
import {
  Account,
  AdlQuantile,
  AssetIndex,
  Balance,
  Kline,
  KlineIntervalEnum,
  Position,
  PositionSideEnum,
  Transfer,
} from '@/types';

import { useNotification } from './useNotification';

const POSITIONS_INFO_REFETCH_INTERVAL = 20_000;
const KLINES_REFETCH_INTERVAL = 20_000;

export const SECURITY_POSITIONS: Record<string, PositionSideEnum[]> = {
  BTCUSDT: [PositionSideEnum.SHORT],
};

export const POSITIONS_REQUEST = 'positions-request';
export const usePositions = () => {
  const { isAuth } = useAuth();

  const positionsRequest = useQuery({
    queryKey: [POSITIONS_REQUEST],
    queryFn: async () => await getPositions(),
    select: data => data.data.positions as { [symbol: string]: Position[] },
    enabled: isAuth,
    staleTime: POSITIONS_INFO_REFETCH_INTERVAL,
    refetchInterval: POSITIONS_INFO_REFETCH_INTERVAL,
  });

  const positionsMap = useMemo(() => {
    return positionsRequest.data || {};
  }, [positionsRequest.data]);

  const positions = useMemo<Position[]>(() => {
    return Object.values(positionsMap).reduce((acc: Position[], [pos1, pos2]: Position[]) => {
      if (pos1.positionAmt !== 0) acc.push(pos1);
      if (pos2.positionAmt !== 0) acc.push(pos2);
      return acc;
    }, []);
  }, [positionsMap]);

  const { tradePositions, securityPositions } = useMemo(
    () =>
      positions.reduce(
        (acc, position) => {
          if (
            Object.keys(SECURITY_POSITIONS).includes(position.symbol) &&
            SECURITY_POSITIONS[position.symbol].includes(position.positionSide)
          ) {
            acc.securityPositions.push(position);
          } else {
            acc.tradePositions.push(position);
          }
          return acc;
        },
        { tradePositions: [], securityPositions: [] } as Record<string, Position[]>
      ),
    [positions]
  );

  const positionsValue = useMemo(
    () =>
      positions.reduce((acc, position) => {
        return acc + Math.abs(position.notional);
      }, 0),
    [positions]
  );

  const securityPositionsValue = useMemo(
    () => securityPositions.reduce((acc, position) => acc + Math.abs(position.notional), 0),
    [securityPositions]
  );

  const tradePositionsValue = useMemo(
    () => tradePositions.reduce((acc, position) => acc + Math.abs(position.notional), 0),
    [tradePositions]
  );

  const symbols = useMemo(() => Object.keys(positionsMap).sort(), [positionsMap]);
  const colorSet = useMemo(
    () => generateColorSet(BASIC_GENERATION_COLOR, symbols.length),
    [symbols]
  );

  const currentPositionsSymbols = useMemo(
    () => Array.from(new Set(positions ? positions.map(pos => pos.symbol) : [])),
    [positions]
  );

  const getSymbolColor = useCallback(
    (symbol: string) => {
      const index = symbols.findIndex(s => s === symbol) || 0;
      return colorSet[index];
    },
    [colorSet, symbols]
  );

  return {
    positionsMap,
    positions,
    currentPositionsSymbols,
    positionsValue,
    tradePositions,
    securityPositions,
    securityPositionsValue,
    tradePositionsValue,
    positionsRequest,
    getSymbolColor,
  };
};

export const useSymbols = () => {
  const { positionsMap } = usePositions();
  const symbols = useMemo(() => Object.keys(positionsMap).sort(), [positionsMap]);

  return symbols;
};

export const BALANCE_REQUEST = 'balance-request';
export const useBalance = () => {
  const { isAuth } = useAuth();

  const balanceRequest = useQuery({
    queryKey: [BALANCE_REQUEST],
    queryFn: async () => await getBalance(),
    select: data => data.data as Balance,
    enabled: isAuth,
    staleTime: POSITIONS_INFO_REFETCH_INTERVAL,
    refetchInterval: POSITIONS_INFO_REFETCH_INTERVAL,
  });

  const balance = useMemo(() => {
    return balanceRequest.data;
  }, [balanceRequest.data]);

  const equityBalance = useMemo(() => {
    return balance?.marginBalance;
  }, [balance]);

  const availableBalance = useMemo(() => {
    return balance?.availableBalance;
  }, [balance]);

  return { balance, equityBalance, availableBalance, balanceRequest };
};

export const ACCOUNT_REQUEST = 'account-request';
export const useAccount = () => {
  const { isAuth } = useAuth();

  const accountRequest = useQuery({
    queryKey: [ACCOUNT_REQUEST],
    queryFn: async () => await getAccount(),
    select: data => data.data as Account,
    enabled: isAuth,
    staleTime: POSITIONS_INFO_REFETCH_INTERVAL,
    refetchInterval: POSITIONS_INFO_REFETCH_INTERVAL,
  });

  const account = useMemo(() => accountRequest.data, [accountRequest.data]);
  const assets = useMemo(() => account?.assets || [], [account]);

  const marginRatio = useMemo(() => {
    const maintMargin = account?.totalMaintMargin || 0;
    const marginBalance = account?.totalMarginBalance;
    return marginBalance ? round((maintMargin / marginBalance) * 100, 2) : 0;
  }, [account]);

  return { account, assets, marginRatio, accountRequest };
};

export const KLINES_REQUEST = 'klines-request';
export const useKlines = (
  symbol: string,
  interval: KlineIntervalEnum,
  startTs?: number,
  endTs?: number,
  refetch: boolean = true
) => {
  const { isAuth } = useAuth();

  const klinesRequest = useQuery({
    queryKey: [KLINES_REQUEST, symbol, interval, startTs, endTs],
    queryFn: async () => await getKlines(symbol, interval, startTs, endTs, 1499),
    select: data => data.data as Kline[],
    enabled: isAuth,
    staleTime: refetch ? KLINES_REFETCH_INTERVAL : undefined,
    refetchInterval: refetch ? KLINES_REFETCH_INTERVAL : undefined,
  });

  const klines = useMemo(() => {
    return klinesRequest.data;
  }, [klinesRequest.data]);

  return { klines, klinesRequest };
};

export const ASSET_INDEX_REQUEST = 'asset-index-request';
export const useAssetIndex = (symbol?: string) => {
  const { isAuth } = useAuth();

  const assetIndexRequest = useQuery({
    queryKey: [ASSET_INDEX_REQUEST, symbol],
    queryFn: async () => await getAssetIndex(symbol),
    select: data => data.data as AssetIndex[],
    enabled: isAuth,
    staleTime: POSITIONS_INFO_REFETCH_INTERVAL,
    refetchInterval: POSITIONS_INFO_REFETCH_INTERVAL,
  });

  const assetIndex = useMemo(() => {
    return assetIndexRequest.data;
  }, [assetIndexRequest.data]);

  return { assetIndex, assetIndexRequest };
};

export const ADL_QUANTILE_REQUEST = 'adl-quantile-request';
export const useAdlQuantile = (symbol?: string) => {
  const { isAuth } = useAuth();

  const adlQuantileRequest = useQuery({
    queryKey: [ADL_QUANTILE_REQUEST, symbol],
    queryFn: async () => await getAdlQuantile(symbol),
    select: data => data.data as AdlQuantile[],
    enabled: isAuth,
    staleTime: Infinity,
  });

  const adlQuantile = useMemo(() => {
    return adlQuantileRequest.data;
  }, [adlQuantileRequest.data]);

  return { adlQuantile, adlQuantileRequest };
};

export const TRANSFERS_REQUEST = 'transfers-request';
const SET_TRANSFER_ENTRY_PRICE_MUTATION = 'set-transfer-entry-price-mutation';
export const useTransfers = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();
  const queryClient = useQueryClient();

  const transfersRequest = useQuery({
    queryKey: [TRANSFERS_REQUEST],
    queryFn: async () => await getTransfers(),
    select: data => data.data as Transfer[],
    enabled: isAuth,
  });

  const transfers = useMemo(() => {
    return transfersRequest.data || [];
  }, [transfersRequest.data]);

  const setTransferEntryPriceMutation = useMutation({
    mutationKey: [SET_TRANSFER_ENTRY_PRICE_MUTATION],
    mutationFn: async ({ transferId, entryPrice }: { transferId: number; entryPrice: number }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await setTransferEntryPrice(transferId, entryPrice);
      info({ title: `Transfer entry price updated` });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TRANSFERS_REQUEST] });
    },
    onError: handleError,
  });

  return { transfers, transfersRequest, setTransferEntryPriceMutation };
};

export const SET_LEVERAGE_MUTATION = 'set-leverage-mutation';
export const usePositionLeverage = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();

  const queryClient = useQueryClient();

  const setLeverageMutation = useMutation({
    mutationKey: [SET_LEVERAGE_MUTATION],
    mutationFn: async ({ symbol, leverage }: { symbol: string; leverage: number }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await setLeverage(symbol, leverage);
      info({ title: `Leverage of ${symbol} set to ${leverage}` });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [POSITIONS_REQUEST] });
      queryClient.invalidateQueries({ queryKey: [ACCOUNT_REQUEST] });
    },
    onError: handleError,
  });

  return setLeverageMutation;
};
