import { useMemo, useState } from 'react';
import { ChartCandlestick, Gem } from 'lucide-react';

import { StrategiesSelect } from '@/components/blocks/StrategiesSelect/StrategiesSelect';
import { SymbolsParameterChart } from '@/components/blocks/SymbolsParameterChart/SymbolsParameterChart';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { aggregateValuesBySymbol, getHourlyPeriodRanges } from '@/hooks/useStatistic';
import { isReduceOrder } from '@/lib/common';
import { cn } from '@/lib/utils';
import { AnyStrategy, ReportResponse } from '@/types';

import { SymbolsTotalParameterChart } from './SymbolsTotalParameterChart';

type StrategiesReportChartsProps = { reportData?: ReportResponse; fromTs: number; toTs: number };

export const StrategiesReportCharts = ({
  reportData,
  fromTs,
  toTs,
}: StrategiesReportChartsProps) => {
  const [statisticType, setStatisticType] = useState('profit');
  const [isAggregate, setIsAggregate] = useState(true);
  const [selectedStrategies, setSelectedStrategies] = useState<AnyStrategy[]>([]);

  const strategiesIds = useMemo(() => selectedStrategies.map(s => s.id), [selectedStrategies]);

  const periodDates = useMemo(() => getHourlyPeriodRanges(fromTs, toTs), [fromTs, toTs]);

  const tradesByPeriods = useMemo(() => {
    const selectedTrades = [...(reportData?.trades || [])]
      .filter(t => t.strategyId && strategiesIds.includes(t.strategyId))
      .sort((a, b) => a.serverTimestamp - b.serverTimestamp);

    return periodDates.map(({ startDate, endDate }) => {
      const periodEndTs = endDate.getTime();
      const lastTradeChunkIndex = selectedTrades.findIndex(t => t.serverTimestamp >= periodEndTs);
      const tradeChunk = selectedTrades.splice(
        0,
        lastTradeChunkIndex > -1 ? lastTradeChunkIndex : selectedTrades.length
      );

      return {
        startDate,
        endDate,
        trades: tradeChunk,
      };
    });
  }, [periodDates, reportData, strategiesIds]);

  const strategiesSymbols = useMemo(
    () =>
      Array.from(
        selectedStrategies.reduce((acc, s) => {
          acc.add(s.symbol);
          return acc;
        }, new Set<string>())
      ),
    [selectedStrategies]
  );

  const strategiesProfitByPeriods = useMemo(
    () =>
      tradesByPeriods.map(({ startDate, endDate, trades }) => {
        const values = trades.reduce(
          (acc, trade) => {
            const profit = (trade.operationProfit || 0) + trade.commission;

            if (trade.symbol in acc) acc[trade.symbol] += profit;
            else acc[trade.symbol] = profit;
            return acc;
          },
          Object.fromEntries(strategiesSymbols.map(s => [s, 0])) as Record<string, number>
        );

        return {
          date: startDate,
          endDate,
          values,
        };
      }),
    [tradesByPeriods, strategiesSymbols]
  );

  const strategiesTradesByPeriods = useMemo(
    () =>
      tradesByPeriods.map(({ startDate, endDate, trades }) => {
        const values = trades.reduce(
          (acc, trade) => {
            if (isReduceOrder(trade)) {
              if (trade.symbol in acc) acc[trade.symbol] += 1;
              else acc[trade.symbol] = 1;
            }
            return acc;
          },
          Object.fromEntries(strategiesSymbols.map(s => [s, 0])) as Record<string, number>
        );

        return {
          date: startDate,
          endDate,
          values,
        };
      }),
    [tradesByPeriods, strategiesSymbols]
  );

  const totalTradesData = useMemo(
    () => aggregateValuesBySymbol(strategiesTradesByPeriods),
    [strategiesTradesByPeriods]
  );

  const totalNetProfitData = useMemo(
    () => aggregateValuesBySymbol(strategiesProfitByPeriods),
    [strategiesProfitByPeriods]
  );

  const historyData = useMemo(() => {
    if (statisticType === 'profit') return strategiesProfitByPeriods;
    if (statisticType === 'trades') return strategiesTradesByPeriods;
    return [];
  }, [statisticType, strategiesProfitByPeriods, strategiesTradesByPeriods]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-4">
          <Tabs
            variant="filled"
            value={statisticType}
            onValueChange={value => setStatisticType(value)}
          >
            <TabsList className="gap-1">
              <TabsTrigger value="profit">
                <div className="flex items-center">
                  <Gem className="mr-2 size-5" /> Net Profit
                </div>
              </TabsTrigger>
              <TabsTrigger value="trades">
                <div className="flex items-center">
                  <ChartCandlestick className="mr-2 size-5" /> Trades
                </div>
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <Switch
            label="Aggregate"
            size="sm"
            checked={isAggregate}
            onCheckedChange={setIsAggregate}
          />
        </div>

        <div className={cn('flex items-center gap-2')}>
          <span className="text-sm text-secondary-foreground">Strategies:</span>
          <StrategiesSelect selected={selectedStrategies} onSelect={setSelectedStrategies} />
        </div>
      </div>

      <div className="mt-4 flex gap-4">
        <div className="shrink-0 grow-0 basis-[260px]">
          {statisticType === 'profit' ? (
            <SymbolsTotalParameterChart label="Net Profit" data={totalNetProfitData} isCurrency />
          ) : null}
          {statisticType === 'trades' ? (
            <SymbolsTotalParameterChart label="Trades" data={totalTradesData} />
          ) : null}
        </div>
        <div className="grow">
          <SymbolsParameterChart
            label="Net Profit"
            data={historyData}
            symbols={strategiesSymbols}
            isCurrency={['profit'].includes(statisticType)}
            mode={isAggregate ? 'aggregate' : 'absolute'}
          />
        </div>
      </div>
    </div>
  );
};
