import { useCallback, useMemo, useState } from 'react';

import { CenteredSpinner } from '@/components/ui/spinner';
import { Table, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { TableBody } from '@/components/ui/table';
import { TableCell } from '@/components/ui/table';
import { useDashboardContext } from '@/context/DashboardContext';
import { usePositions } from '@/hooks/usePositions';
import { formatNumber } from '@/lib/common';
import { cn } from '@/lib/utils';
import {
  AnyStrategy,
  Position,
  PositionSideEnum,
  SortingType,
  StrategyStateEnum,
  StrategyTypeEnum,
} from '@/types';
import { useAllStrategies } from '@/hooks/useStrategies';
import { StrategyTypeLabel } from '@/components/blocks/StrategyStatusIcon/StrategyTypeLabel';

export const PositionsList = () => {
  const {
    positions,
    positionsRequest: { isLoading },
  } = usePositions();
  const { isTokenSize, selectIsTokenSize } = useDashboardContext();
  const [sorting, setSorting] = useState<SortingType<any>>();

  const togglePositionSize = useCallback(() => {
    selectIsTokenSize(!isTokenSize);
  }, [isTokenSize, selectIsTokenSize]);

  const formattedPositions = useMemo(
    () => (positions || []).map(pos => ({ ...pos, margin: Math.abs(pos.notional) / pos.leverage })),
    [positions]
  );

  const sortedPositions = useMemo(() => {
    if (sorting) {
      return [...formattedPositions].sort((a, b) =>
        // @ts-expect-error: only 'margin' and 'unRealizedProfit' keys
        sorting.dir == 'asc' ? a[sorting.key] - b[sorting.key] : b[sorting.key] - a[sorting.key]
      );
    }
    return formattedPositions;
  }, [sorting, formattedPositions]);

  return (
    <>
      {isLoading ? <CenteredSpinner /> : null}

      {!positions.length ? (
        <div className="my-8 text-center text-base text-third-foreground">
          {isLoading ? 'Loading' : `No positions found`}
        </div>
      ) : (
        <Table onChangeSorting={setSorting}>
          <TableHeader>
            <TableRow>
              <TableHead>Symbol</TableHead>
              <TableHead isNumeric onClick={togglePositionSize} className="cursor-pointer">
                {isTokenSize ? 'Tokens' : 'Size, $'}
              </TableHead>
              <TableHead isNumeric>Entry price</TableHead>
              <TableHead isNumeric>Mark price</TableHead>
              <TableHead isNumeric>Liq. price</TableHead>
              <TableHead isNumeric sortable="margin">
                Margin
              </TableHead>
              <TableHead isNumeric sortable="unRealizedProfit">
                PNL
              </TableHead>
              <TableHead isNumeric>Break price</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedPositions.map((position, index) => (
              <PositionRow key={`${position.symbol}-${index}`} {...position} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

type PositionRowProps = Position & { margin: number };
const PositionRow = ({
  symbol,
  positionAmt,
  positionSide,
  leverage,
  liquidationPrice,
  markPrice,
  entryPrice,
  notional,
  unRealizedProfit,
  breakEvenPrice,
  margin,
}: PositionRowProps) => {
  const { isTokenSize, selectSymbol } = useDashboardContext();
  const { activeStrategies, terminatedStrategies } = useAllStrategies();

  const positionSideColor = useMemo(() => {
    if (positionSide === PositionSideEnum.SHORT) return 'border-sell';
    return 'border-buy';
  }, [positionSide]);
  const profitColor = useMemo(() => {
    if (unRealizedProfit < 0) return 'text-red';
    return 'text-green';
  }, [unRealizedProfit]);

  const symbolStrategies = useMemo(
    () =>
      [...activeStrategies, ...terminatedStrategies]
        .filter(s => s.symbol == symbol && positionSide == PositionSideEnum.LONG)
        .reduce(
          (acc, strategy) => {
            if (strategy.state == StrategyStateEnum.Active) acc[strategy.type] = strategy;
            else if (!(strategy.type in acc)) acc[strategy.type] = strategy;
            return acc;
          },
          {} as Record<StrategyTypeEnum, AnyStrategy>
        ),
    [activeStrategies, terminatedStrategies, symbol, positionSide]
  );

  return (
    <TableRow className="font-normal">
      <TableCell className="px-4 py-2">
        <div
          className={cn('cursor-pointer border-l-2 pl-1', positionSideColor)}
          onClick={() => selectSymbol(symbol)}
        >
          <div className="flex items-center gap-2">
            <p className="font-medium">{symbol}</p>
            <div className="flex items-center ">
              {Object.values(symbolStrategies).map(strategy => (
                <StrategyTypeLabel
                  key={strategy.id}
                  type={strategy.type}
                  size={4}
                  withText={false}
                  lighted={strategy.state == StrategyStateEnum.Active}
                />
              ))}
            </div>
          </div>

          <p className="text-xs">
            {positionSide} <span className="text-third-foreground">x{leverage}</span>
          </p>
        </div>
      </TableCell>
      <TableCell isNumeric>
        {isTokenSize ? formatNumber(positionAmt, 'full') : formatNumber(notional, 2)}
      </TableCell>
      <TableCell isNumeric>{formatNumber(entryPrice)}</TableCell>
      <TableCell isNumeric>{formatNumber(markPrice)}</TableCell>
      <TableCell isNumeric className="text-orange">
        {liquidationPrice ? formatNumber(liquidationPrice) : '--'}
      </TableCell>
      <TableCell isNumeric>{formatNumber(margin, 2)} USDT</TableCell>
      <TableCell isNumeric className={profitColor}>
        {formatNumber(unRealizedProfit, 2)} USDT
      </TableCell>
      <TableCell isNumeric>{formatNumber(breakEvenPrice)}</TableCell>
    </TableRow>
  );
};
