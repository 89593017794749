import { useCallback, useEffect, useMemo, useState } from 'react';
import { TokenIcon } from '@token-icons/react';
import { ChevronDown } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePositions } from '@/hooks/usePositions';
import { arraysEqual } from '@/lib/utils';

type SymbolsSelectProps = {
  symbols: string[];
  selected: string[];
  onSelect: (symbols: string[]) => void;
};
export const SymbolsSelect = ({ symbols, selected, onSelect }: SymbolsSelectProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentPositionsSymbols } = usePositions();

  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);

  useEffect(() => {
    setSelectedSymbols(selected);
  }, [selected]);

  const isAllSelected = useMemo(
    () => arraysEqual(symbols, selectedSymbols),
    [symbols, selectedSymbols]
  );

  const isCurrentPositionsSelected = useMemo(
    () => arraysEqual(currentPositionsSymbols, selectedSymbols),
    [currentPositionsSymbols, selectedSymbols]
  );
  const toggleAllSelected = useCallback(
    (checked: boolean) => {
      if (checked) onSelect(symbols);
      else onSelect([]);
    },
    [symbols, onSelect]
  );

  const toggleCurrentSelected = useCallback(
    (checked: boolean) => {
      if (checked) onSelect(currentPositionsSymbols);
      else onSelect([]);
    },
    [currentPositionsSymbols, onSelect]
  );

  const toggleSymbolSelect = useCallback(
    (symbol: string, checked: boolean) => {
      if (checked) onSelect([...selectedSymbols, symbol]);
      else onSelect(selectedSymbols.filter(s => s !== symbol));
    },
    [selectedSymbols, onSelect]
  );

  return (
    <DropdownMenu open={isOpen} onOpenChange={onClose}>
      <DropdownMenuTrigger asChild>
        <div
          className="flex max-h-[40px] max-w-[340px] cursor-pointer flex-wrap items-center gap-1 self-baseline overflow-auto rounded-md border border-border p-1 text-xs"
          onClick={onOpen}
        >
          {!selectedSymbols.length ? (
            <div className="mx-2 text-third-foreground">Select symbols...</div>
          ) : null}
          {selectedSymbols.map((symbol, index) => (
            <div key={index} className="whitespace-nowrap rounded-lg bg-third p-1 px-2">
              {symbol}
            </div>
          ))}

          <ChevronDown className="ml-auto text-secondary-foreground" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[400px] w-56 overflow-auto" align="end">
        <DropdownMenuCheckboxItem
          checked={isCurrentPositionsSelected}
          onCheckedChange={toggleCurrentSelected}
          onSelect={e => e.preventDefault()}
        >
          Current Positions ({currentPositionsSymbols.length})
        </DropdownMenuCheckboxItem>

        <DropdownMenuCheckboxItem
          checked={isAllSelected}
          onCheckedChange={toggleAllSelected}
          onSelect={e => e.preventDefault()}
        >
          All Symbols ({symbols.length})
        </DropdownMenuCheckboxItem>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          {symbols.map((symbol, index) => (
            <DropdownMenuCheckboxItem
              key={`${symbol}-${index}`}
              checked={selectedSymbols.includes(symbol)}
              onCheckedChange={checked => toggleSymbolSelect(symbol, checked)}
              onSelect={e => e.preventDefault()}
            >
              <div className="flex items-center gap-1">
                <TokenIcon symbol={symbol.slice(0, -4).toUpperCase()} size="24" variant="branded" />
                <span className="font-medium">{symbol}</span>
              </div>
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
