import { useCallback, useEffect, useMemo, useState } from 'react';
import { Check, ChevronsUpDown, X } from 'lucide-react';

import {
  CalculatedOrderParams,
  ConfirmOrderPlace,
} from '@/components/blocks/modals/ConfirmOrderPlace/ConfirmOrderPlace';
import { SymbolSearchSelect } from '@/components/blocks/SymbolSearchSelect/SymbolSearchSelect';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDashboardContext } from '@/context/DashboardContext';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useOrderActions } from '@/hooks/useOrders';
import { usePositionLeverage, usePositions } from '@/hooks/usePositions';
import { formatNumber, getReadableError } from '@/lib/common';
import {
  OrderCreateParams,
  OrderTypeEnum,
  PositionSideEnum,
  SideEnum,
  TimeInForceEnum,
  WorkingTypeEnum,
} from '@/types';

enum OrderSideEnum {
  'OPEN LONG' = 'OPEN LONG',
  'OPEN SHORT' = 'OPEN SHORT',
  'CLOSE LONG' = 'CLOSE LONG',
  'CLOSE SHORT' = 'CLOSE SHORT',
}

const USDT_ASSET = 'USDT';
type OrderControlProps = { onClose: () => void };
export const OrderControl = ({ onClose }: OrderControlProps) => {
  const { activeSymbol, selectSymbol, isTokenSize, selectIsTokenSize } = useDashboardContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [confirmedOrderParams, setConfirmedOrderParams] = useState<CalculatedOrderParams>();
  const [sizeStr, setSizeStr] = useState<string>();
  const [priceStr, setPriceStr] = useState<string>();
  const [stopPriceStr, setStopPriceStr] = useState<string>();
  const [side, setSide] = useState<SideEnum>(SideEnum.BUY);
  const [positionSide, setPositionSide] = useState<PositionSideEnum>(PositionSideEnum.LONG);
  const [workingType, setWorkingType] = useState<WorkingTypeEnum>(WorkingTypeEnum.CONTRACT_PRICE);
  const [orderType, setOrderType] = useState<OrderTypeEnum>(OrderTypeEnum.LIMIT);
  const [localLeverage, setLocalLeverage] = useState(-1);
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();

  const { calculateOrderMutation, placeOrderMutation } = useOrderActions();
  const setLeverage = usePositionLeverage();

  const isValidSymbol = useMemo(() => activeSymbol.endsWith(USDT_ASSET), [activeSymbol]);

  const { positionsMap } = usePositions();
  const currentPosition = useMemo(() => {
    if (activeSymbol in positionsMap)
      return positionsMap[activeSymbol].find(pos => pos.positionSide === positionSide);
    return undefined;
  }, [activeSymbol, positionsMap, positionSide]);

  const leverage = useMemo(() => {
    if (currentPosition) return currentPosition.leverage;
    return -1;
  }, [currentPosition]);

  useEffect(() => setLocalLeverage(leverage), [leverage]);

  const baseAsset = useMemo(() => {
    if (isValidSymbol) return activeSymbol.slice(0, -4);
    return undefined;
  }, [isValidSymbol, activeSymbol]);

  const handleSetLeverage = useCallback(() => {
    setLeverage.mutateAsync({ symbol: activeSymbol, leverage: localLeverage });
  }, [activeSymbol, localLeverage, setLeverage]);

  const handleSetSide = useCallback((value: OrderSideEnum) => {
    if (value === OrderSideEnum['OPEN LONG']) {
      setPositionSide(PositionSideEnum.LONG);
      setSide(SideEnum.BUY);
    }
    if (value === OrderSideEnum['OPEN SHORT']) {
      setPositionSide(PositionSideEnum.SHORT);
      setSide(SideEnum.SELL);
    }
    if (value === OrderSideEnum['CLOSE LONG']) {
      setPositionSide(PositionSideEnum.LONG);
      setSide(SideEnum.SELL);
    }
    if (value === OrderSideEnum['CLOSE SHORT']) {
      setPositionSide(PositionSideEnum.SHORT);
      setSide(SideEnum.BUY);
    }
  }, []);

  const handleSetMaxPosition = useCallback(
    (amt: number) => {
      setSizeStr(amt.toString());
      selectIsTokenSize(true);
    },
    [selectIsTokenSize]
  );

  const size = useMemo(() => Math.abs(parseFloat(sizeStr || '0')), [sizeStr]);
  const price = useMemo(() => parseFloat(priceStr || '0'), [priceStr]);
  const stopPrice = useMemo(() => parseFloat(stopPriceStr || '0'), [stopPriceStr]);
  const isReduce = useMemo(
    () =>
      (positionSide == PositionSideEnum.LONG && side == SideEnum.SELL) ||
      (positionSide == PositionSideEnum.SHORT && side == SideEnum.BUY),
    [positionSide, side]
  );

  const isValid = useMemo(() => {
    if (!size || !isValidSymbol || !positionSide || !side) return false;

    if (orderType == OrderTypeEnum.LIMIT && !price) return false;
    if (orderType == OrderTypeEnum.STOP_MARKET && (!stopPrice || !workingType)) return false;
    if (orderType == OrderTypeEnum.STOP_LIMIT && (!price || !stopPrice || !workingType))
      return false;

    return true;
  }, [size, isValidSymbol, positionSide, side, orderType, price, stopPrice, workingType]);

  const handleOrderCalculate = useCallback(() => {
    if (!size) {
      return;
    }

    const orderParams: OrderCreateParams = {
      symbol: activeSymbol,
      type: orderType,
      positionSide,
      side,
      qty: isTokenSize ? size : 0,
    };

    if (orderType === OrderTypeEnum.LIMIT) {
      orderParams['price'] = price;
      orderParams['timeInForce'] = TimeInForceEnum.GTC;
    }
    if (orderType === OrderTypeEnum.STOP_MARKET) {
      orderParams['stopPrice'] = stopPrice;
      orderParams['workingType'] = workingType;
      orderParams['timeInForce'] = TimeInForceEnum.GTC;
    }
    if (orderType === OrderTypeEnum.STOP_LIMIT) {
      orderParams['price'] = price;
      orderParams['stopPrice'] = stopPrice;
      orderParams['workingType'] = workingType;
      orderParams['timeInForce'] = TimeInForceEnum.GTC;
    }

    setIsLoading(true);
    setError('');
    calculateOrderMutation
      .mutateAsync({
        ...orderParams,
        spentUsdt: isTokenSize ? undefined : size,
      })
      .then(res => {
        setConfirmedOrderParams(res.data as CalculatedOrderParams);
        onOpenConfirm();
      })
      .catch(err => setError(getReadableError(err)))
      .finally(() => setIsLoading(false));
  }, [
    onOpenConfirm,
    size,
    activeSymbol,
    isTokenSize,
    positionSide,
    side,
    orderType,
    price,
    stopPrice,
    workingType,
    calculateOrderMutation,
  ]);

  const handlePlaceOrder = useCallback(() => {
    if (confirmedOrderParams?.orderParams) {
      setIsLoading(true);
      placeOrderMutation
        .mutateAsync(confirmedOrderParams?.orderParams)
        .then(() => onCloseConfirm())
        .catch(err => setError(getReadableError(err)))
        .finally(() => setIsLoading(false));
    }
  }, [placeOrderMutation, confirmedOrderParams, onCloseConfirm]);

  return (
    <div className="flex max-h-full flex-col">
      <div className="flex items-center justify-between px-3 pt-2">
        <span className="text-section-heading">Create order</span>
        <Button variant="ghost" size="icon" className="size-7" onClick={onClose}>
          <X />
        </Button>
      </div>

      <div className="flex flex-col gap-4 overflow-y-auto  p-3">
        <Tabs
          value={orderType}
          onValueChange={val => setOrderType(val as OrderTypeEnum)}
          variant="unstyled"
          size="xs"
        >
          <TabsList className="gap-3">
            {Object.entries(OrderTypeEnum).map(([key, value]) => (
              <TabsTrigger key={key} value={value}>
                {key.replaceAll('_', ' ')}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        <div className="flex items-center gap-3">
          <div className="flex items-center">
            <p className="mr-2 text-sm">Symbol:</p>
            <SymbolSearchSelect
              symbol={activeSymbol}
              size="xs"
              variant="outline"
              onSetSymbol={val => selectSymbol(val)}
            />
          </div>
          <div className="flex items-center gap-1">
            <p className="mr-2 text-sm">Leverage:</p>
            <Input
              size="sm"
              className="w-12 rounded border border-border p-1 text-sm"
              type="number"
              value={localLeverage}
              onChange={e => setLocalLeverage(Number(e.target.value))}
            />
            {leverage !== localLeverage ? (
              <Button
                className="size-8 p-0"
                size="sm"
                variant="default"
                isLoading={setLeverage.isPending}
                onClick={handleSetLeverage}
              >
                <Check />
              </Button>
            ) : null}
          </div>
        </div>

        <RadioGroup
          defaultValue={OrderSideEnum['OPEN LONG']}
          onValueChange={handleSetSide}
          className="flex flex-row"
        >
          <div className="flex flex-col gap-2">
            <RadioGroupItem
              value={OrderSideEnum['OPEN LONG']}
              id={OrderSideEnum['OPEN LONG']}
              label={OrderSideEnum['OPEN LONG']}
              indicatorClassName="text-buy"
            />
            <RadioGroupItem
              value={OrderSideEnum['OPEN SHORT']}
              id={OrderSideEnum['OPEN SHORT']}
              label={OrderSideEnum['OPEN SHORT']}
              indicatorClassName="text-sell"
            />
          </div>
          <div className="flex flex-col gap-2">
            <RadioGroupItem
              value={OrderSideEnum['CLOSE LONG']}
              id={OrderSideEnum['CLOSE LONG']}
              label={OrderSideEnum['CLOSE LONG']}
              indicatorClassName="text-sell"
            />
            <RadioGroupItem
              value={OrderSideEnum['CLOSE SHORT']}
              id={OrderSideEnum['CLOSE SHORT']}
              label={OrderSideEnum['CLOSE SHORT']}
              indicatorClassName="text-buy"
            />
          </div>
        </RadioGroup>

        <div className="flex flex-col gap-2">
          {[OrderTypeEnum.STOP_LIMIT, OrderTypeEnum.STOP_MARKET].includes(orderType) ? (
            <div className="relative">
              <Input
                size="sm"
                type="number"
                value={stopPriceStr ?? ''}
                placeholder="Stop price"
                onChange={val => setStopPriceStr(val.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <DropdownSelect
                  items={Object.values(WorkingTypeEnum)}
                  value={workingType}
                  onSelect={val => setWorkingType(val as WorkingTypeEnum)}
                >
                  <Button size="xs" variant="outline" className="rounded text-xs">
                    {workingType.split('_')[0]}
                    <ChevronsUpDown className="ml-1 size-4" />
                  </Button>
                </DropdownSelect>
              </div>
            </div>
          ) : null}

          {[OrderTypeEnum.LIMIT, OrderTypeEnum.STOP_LIMIT].includes(orderType) ? (
            <Input
              size="sm"
              type="number"
              value={priceStr ?? ''}
              placeholder="Price"
              onChange={val => setPriceStr(val.target.value)}
            />
          ) : null}

          <div>
            <div className="relative">
              <Input
                size="sm"
                type="number"
                value={sizeStr ?? ''}
                placeholder="Size"
                onChange={val => setSizeStr(val.target.value)}
              />

              <div className="absolute inset-y-0 right-0 flex items-center">
                <DropdownSelect
                  items={[baseAsset || '', USDT_ASSET]}
                  value={isTokenSize ? baseAsset || '' : USDT_ASSET}
                  onSelect={val => selectIsTokenSize(val !== USDT_ASSET)}
                >
                  <Button size="xs" variant="outline" className="rounded text-xs">
                    {isTokenSize ? baseAsset || '' : USDT_ASSET}
                    <ChevronsUpDown className="ml-1" />
                  </Button>
                </DropdownSelect>
              </div>
            </div>

            {isReduce ? (
              <div className="flex justify-between items-center mt-1">
                {currentPosition ? (
                  <>
                    <p className="text-third-foreground text-xs">
                      Current:{' '}
                      <span className="text-secondary-foreground">
                        {' '}
                        {currentPosition?.positionAmt} {activeSymbol} (
                        {formatNumber(currentPosition?.notional, 2)} $)
                      </span>
                    </p>
                    <Button
                      size="xs"
                      variant="ghost"
                      className="rounded text-xs p-2 h-6"
                      onClick={() => handleSetMaxPosition(currentPosition.positionAmt)}
                    >
                      MAX
                    </Button>
                  </>
                ) : (
                  'no'
                )}
              </div>
            ) : null}
          </div>
        </div>

        {error ? <p className="text-sm font-semibold text-destructive">{error}</p> : null}

        <Button size="sm" disabled={!isValid} isLoading={isLoading} onClick={handleOrderCalculate}>
          Calculate
        </Button>
      </div>

      {isOpenConfirm && confirmedOrderParams ? (
        <ConfirmOrderPlace
          {...confirmedOrderParams}
          error={error}
          onCancel={onCloseConfirm}
          onConfirm={handlePlaceOrder}
        />
      ) : null}
    </div>
  );
};

type DropdownSelectProps = {
  children: React.ReactNode;
  value: string;
  items: string[];
  onSelect: (val: string) => void;
};
const DropdownSelect = ({ value, items, children, onSelect }: DropdownSelectProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuRadioGroup value={value} onValueChange={onSelect}>
          {items.map(item => (
            <DropdownMenuRadioItem key={item} value={item}>
              {item}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
