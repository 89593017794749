import { useMemo } from 'react';
import { ShieldAlert } from 'lucide-react';
import { CartesianGrid, Line, LineChart, ReferenceDot, XAxis, YAxis } from 'recharts';

import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import {
  KAERU_BUY_SIGNAL_COLOR,
  KAERU_RSI_COLOR,
  KAERU_SELL_SIGNAL_COLOR,
  KAERU_SMA_COLOR,
} from '@/lib/colors';
import { formatDate, formatNumber, getReadableDuration, round } from '@/lib/common';
import {
  GridStrategyCalculatedParams,
  KaeruSignalEnum,
  KaeruStrategyCalculatedParams,
} from '@/types';

type GridStrategyInfoFieldsProps = GridStrategyCalculatedParams;
export const GridStrategyInfoFields = ({
  symbol,
  mode,
  minPrice,
  maxPrice,
  currentPrice,
  step,
  initialCapital,
  leverage,
  multiplier,
  tradeSize,
  levels,
  securityCapital,
  leveragedCapital,
  closestLevel,
  levelsForInvestment,
  priceForLongTrades,
  priceForInvestmentPosition,
  maxPositionSize,
  totalMaxSpent,
  capitalUsage,
  currentPosition,
  remainingPosition,
  positionForInvestment,
  minTradeProfit,
  maxTradeProfit,
  hedgeEnabled,
  hedgeGap,
}: GridStrategyInfoFieldsProps) => {
  return (
    <div>
      <p className="text-subheading">PARAMS</p>

      <StrategyInfoFieldValue label="Symbol:">{symbol}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Mode:">{mode}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Min price:">
        {formatNumber(minPrice, 'full')}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Max price:">
        {formatNumber(maxPrice, 'full')}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current price:">
        {formatNumber(currentPrice)}$
      </StrategyInfoFieldValue>

      <p className="text-subheading">HEDGE</p>

      <StrategyInfoFieldValue label="Hedge:">
        <div className="flex items-center">
          <ShieldAlert className="mr-1 size-4 text-yellow" />
          {hedgeEnabled ? 'enabled' : 'disabled'}
        </div>
      </StrategyInfoFieldValue>

      {hedgeEnabled ? (
        <StrategyInfoFieldValue label="Hedge gap:">
          {(hedgeGap || 0) * 100}% ({formatNumber(minPrice * (1 - (hedgeGap || 0)))}$)
        </StrategyInfoFieldValue>
      ) : null}

      <p className="text-subheading">LEVELS</p>

      <StrategyInfoFieldValue label="Step:">{round(step * 100, 2)}%</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Levels:">
        {levels.length} ({formatNumber(levels[0])}$ - {formatNumber(levels[levels.length - 1])}$)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Trade profit:">
        {formatNumber(minTradeProfit)}$ - {formatNumber(maxTradeProfit)}$ <br />
        <span className="text-third-foreground">(minus fees)</span>
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current level:">
        {closestLevel} ({formatNumber(levels[closestLevel])}
        $)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Investment levels:">
        {levelsForInvestment}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Reserved for long:">
        {formatNumber(priceForLongTrades, 2)}$
      </StrategyInfoFieldValue>

      <p className="text-subheading">POSITION</p>

      <StrategyInfoFieldValue label="Trade size:">{tradeSize}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Invest position:">
        {positionForInvestment} ({formatNumber(priceForInvestmentPosition, 2)}$)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current position:">
        {currentPosition} ({formatNumber(currentPosition * currentPrice, 2)}
        $)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Remaining position:">
        {remainingPosition}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Max position:">{maxPositionSize}</StrategyInfoFieldValue>

      <p className="text-subheading">CAPITAL</p>

      <StrategyInfoFieldValue label="Leverage:">{leverage}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Multiplier:">{multiplier}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Initial capital:">
        {formatNumber(initialCapital, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Leveraged capital:">
        {formatNumber(leveragedCapital, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Security capital:">
        {formatNumber(securityCapital, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Total max spent:">
        {formatNumber(totalMaxSpent, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Capital usage:">
        {round(capitalUsage * 100, 2)}%
      </StrategyInfoFieldValue>
    </div>
  );
};
type KaeruStrategyInfoFieldsProps = KaeruStrategyCalculatedParams;
export const KaeruStrategyInfoFields = ({
  mode,
  symbol,
  timeframe,
  rsiLength,
  smaLength,
  tradeSize,
  backtest,
  maxMarketTradeSize,
  lastSignal,
}: KaeruStrategyInfoFieldsProps) => {
  const chartConfig = {
    rsi: { color: KAERU_RSI_COLOR, label: 'RSI' },
    sma: { color: KAERU_SMA_COLOR, label: 'SMA' },
    [KaeruSignalEnum.BUY_SIGNAL]: { color: KAERU_BUY_SIGNAL_COLOR },
    [KaeruSignalEnum.SELL_SIGNAL]: { color: KAERU_SELL_SIGNAL_COLOR },
  };

  const filteredRsi = useMemo(() => {
    const firstValueFromEnd = backtest.rsi.length - backtest.rsi.findIndex(Boolean);
    const maxLength = 200;
    const rsiLength = Math.min(maxLength, firstValueFromEnd);
    return backtest.rsi.slice(-rsiLength);
  }, [backtest]);

  const filteredSma = useMemo(() => {
    return backtest.sma.slice(-filteredRsi.length);
  }, [backtest, filteredRsi]);

  const chartData = useMemo(() => {
    const data = [];
    for (let i = 0; i < filteredRsi.length; i++) {
      data.push({
        date: filteredRsi[i].time,
        rsi: filteredRsi[i].value,
        sma: filteredSma[i].value,
      });
    }
    return data;
  }, [filteredRsi, filteredSma]);

  const signalDots = useMemo(() => {
    const firstIndicatorTime = filteredRsi[0].time;
    const filteredSignals = backtest.signals.filter(signal => signal.time > firstIndicatorTime);
    return filteredSignals.map(signal => ({
      type: signal.signal,
      cx: signal.time,
      cy: signal.currentRsi,
    }));
  }, [backtest, filteredRsi]);

  return (
    <div className="flex flex-col gap-1">
      <p className="text-subheading">PARAMS</p>

      <StrategyInfoFieldValue label="Symbol:">{symbol}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Mode:">{mode}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Timeframe:">{timeframe}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="RSI length:">{rsiLength}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="SMA length:">{smaLength}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Trade size:">
        {tradeSize} {symbol}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Max MARKET size:">
        {maxMarketTradeSize} {symbol}
      </StrategyInfoFieldValue>

      <p className="text-subheading">BACKTEST</p>

      <StrategyInfoFieldValue label="Start:">
        {formatDate(new Date(backtest.startTime))}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="End:">
        {formatDate(new Date(backtest.endTime))}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Op. trades:">
        {backtest.operationTrades} ({formatNumber(backtest.operationProfit, 2)} $)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Net profit:">
        {formatNumber(backtest.operationProfit - backtest.fees, 2)} $
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Profit trades:">
        {backtest.profitableTrades}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Loss trades:">{backtest.lossTrades}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Avg. trade [%]:">
        {round(backtest.avgTrade * 100, 2)} %
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Avg. trade duration:">
        {getReadableDuration(backtest.avgTradeDuration)}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Last signal:">{lastSignal}</StrategyInfoFieldValue>

      <div className="my-2">
        <ChartContainer config={chartConfig}>
          <LineChart data={chartData}>
            <CartesianGrid vertical={true} />
            <XAxis
              dataKey="date"
              // interval="equidistantPreserveStart"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              tickFormatter={value => formatDate(new Date(value))}
            />
            <YAxis
              domain={[0, 100]}
              tickLine={false}
              axisLine={false}
              mirror
              hide
              orientation="left"
            />

            <ChartTooltip
              content={
                <ChartTooltipContent
                  labelFormatter={(_, payload) => {
                    if (payload.length) {
                      const date = payload[0].payload.date;
                      return formatDate(new Date(date));
                    }
                  }}
                />
              }
            />
            <ChartLegend content={<ChartLegendContent />} />

            <Line
              key="rsi"
              dataKey="rsi"
              name="rsi"
              label="RSI"
              type="monotone"
              stroke="var(--color-rsi)"
              strokeWidth={1}
              dot={false}
            />
            <Line
              key="sma"
              dataKey="sma"
              name="sma"
              label="SMA"
              type="monotone"
              stroke="var(--color-sma)"
              strokeWidth={2}
              dot={false}
            />

            {signalDots.map((dot, index) => (
              <ReferenceDot
                key={index}
                x={dot.cx}
                y={dot.cy}
                r={2.5}
                fill={`var(--color-${dot.type})`}
                stroke="none"
                isFront
                ifOverflow="visible"
              />
            ))}
          </LineChart>
        </ChartContainer>
      </div>
    </div>
  );
};

type FieldValueProps = {
  label: string;
  children: React.ReactNode;
};
export const StrategyInfoFieldValue = ({ label, children }: FieldValueProps) => {
  return (
    <div className="flex items-start whitespace-nowrap text-sm">
      <p className="shrink-0 grow-0 basis-40 text-secondary-foreground">{label}</p>
      <p className="font-medium">{children}</p>
    </div>
  );
};
