import { useCallback, useMemo, useState } from 'react';
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { Checkbox } from '@/components/ui/checkbox';
import { useKlines } from '@/hooks/usePositions';
import { getHourlyPeriodRanges } from '@/hooks/useStatistic';
import { formatDate, formatNumber, isReduceOrder } from '@/lib/common';
import { IncomeTypeEnum, KlineIntervalEnum, ReportResponse } from '@/types';

const aggregateData = (values: any[], key: string, mode: 'sum' | 'average' | 'last') => {
  if (!values.length) return null;
  if (mode == 'last') return values.slice(-1)[0][key] || 0;

  const sum = values.reduce((acc, value) => acc + (value[key] || 0), 0);
  return mode === 'average' ? sum / values.length : sum;
};

const chartConfig = {
  equity: {
    color: 'var(--teal)',
  },
  available: {
    color: 'var(--green)',
  },
  pnl: {
    color: 'var(--pink)',
  },
  funding: {
    color: 'var(--red)',
  },
  fee: {
    color: 'var(--gray)',
  },
  trades: {
    color: 'var(--purple)',
  },
  profit: {
    color: 'var(--blue)',
  },
  btc: {
    color: 'var(--yellow)',
  },
} satisfies ChartConfig;

type ChartData = {
  date: Date;
  endDate: Date;
  equity: number | null;
  available: number | null;
  pnl: number | null;
  trades: number;
  netProfit: number | null;
  funding: number | null;
  fee: number | null;
  btcPrice: number | null;
};

type BalancesReportChartProps = { reportData?: ReportResponse; fromTs: number; toTs: number };
export const BalancesReportChart = ({ reportData, fromTs, toTs }: BalancesReportChartProps) => {
  const [showEquity, setShowEquity] = useState(true);
  const [showAvailable, setShowAvailable] = useState(false);
  const [showPnl, setShowPnl] = useState(false);
  const [showTrades, setShowTrades] = useState(false);
  const [showNetProfit, setShowNetProfit] = useState(false);
  const [showFunding, setShowFunding] = useState(false);
  const [showFee, setShowFee] = useState(false);
  const [showBTCPrice, setShowBTCPrice] = useState(false);

  const isSmallRange = useMemo(() => toTs - fromTs < 10 * 24 * 3600 * 1000, [toTs, fromTs]);

  const { klines } = useKlines(
    'BTCUSDT',
    isSmallRange ? KlineIntervalEnum.ONE_HOUR : KlineIntervalEnum.ONE_DAY,
    fromTs,
    toTs,
    false
  );

  const chartData = useMemo(() => {
    if (!reportData) return [];

    const periods = getHourlyPeriodRanges(fromTs, toTs);

    const chartData: ChartData[] = periods.map(({ startDate, endDate }) => ({
      date: startDate,
      endDate,
      equity: null,
      available: null,
      pnl: null,
      trades: 0,
      netProfit: null,
      funding: null,
      fee: null,
      btcPrice: null,
    }));

    const balancesData = [...reportData.balances].sort(
      (a, b) => new Date(a.dt).getTime() - new Date(b.dt).getTime()
    );
    const incomesData = [...reportData.incomes].sort(
      (a, b) => a.serverTimestamp - b.serverTimestamp
    );
    const tradesData = [...reportData.trades].sort((a, b) => a.serverTimestamp - b.serverTimestamp);
    const btcPricesData = [...(klines || [])];

    const balanceChunks = [];
    const incomesChunks = [];
    const tradeChunks = [];
    const btcChunks = [];
    for (let dateIndex = 0; dateIndex < chartData.length; dateIndex++) {
      const periodEndDate = chartData[dateIndex].endDate;

      const lastBalanceChunkIndex = balancesData.findIndex(b => new Date(b.dt) >= periodEndDate);
      const balanceChunk = balancesData.splice(
        0,
        lastBalanceChunkIndex > -1 ? lastBalanceChunkIndex : balancesData.length
      );
      balanceChunks.push(balanceChunk);

      const lastIncomeChunkIndex = incomesData.findIndex(
        c => new Date(c.serverTimestamp) >= periodEndDate
      );
      const incomesChunk = incomesData.splice(
        0,
        lastIncomeChunkIndex > -1 ? lastIncomeChunkIndex : incomesData.length
      );
      incomesChunks.push(incomesChunk);

      const periodEndTs = periodEndDate.getTime();
      const lastTradeChunkIndex = tradesData.findIndex(t => t.serverTimestamp >= periodEndTs);
      const tradeChunk = tradesData.splice(
        0,
        lastTradeChunkIndex > -1 ? lastTradeChunkIndex : tradesData.length
      );
      tradeChunks.push(tradeChunk);

      const lastBtcChunkIndex = btcPricesData.findIndex(p => p.openTs > periodEndTs);
      const btcChunk = btcPricesData.splice(
        0,
        lastBtcChunkIndex > -1 ? lastBtcChunkIndex : btcPricesData.length
      );
      btcChunks.push(btcChunk);
    }

    for (let dateIndex = 0; dateIndex < chartData.length; dateIndex++) {
      // Equity, Available, PNL
      const balances = balanceChunks[dateIndex];
      chartData[dateIndex].equity =
        aggregateData(balances, 'marginBalance', 'last') ||
        (dateIndex > 0 ? chartData[dateIndex - 1].equity : null);
      chartData[dateIndex].available =
        aggregateData(balances, 'availableBalance', 'last') ||
        (dateIndex > 0 ? chartData[dateIndex - 1].available : null);
      chartData[dateIndex].pnl =
        aggregateData(balances, 'unrealizedProfit', 'last') ||
        (dateIndex > 0 ? chartData[dateIndex - 1].pnl : null);

      // Funding, Fee
      const incomes = incomesChunks[dateIndex];

      const fundings = incomes.filter(i => i.incomeType == IncomeTypeEnum.FUNDING_FEE);
      const fees = incomes.filter(i => i.incomeType == IncomeTypeEnum.COMMISSION);
      chartData[dateIndex].funding =
        (dateIndex > 0 ? chartData[dateIndex - 1].funding : 0) +
        (aggregateData(fundings, 'income', 'sum') || 0);
      chartData[dateIndex].fee =
        (dateIndex > 0 ? chartData[dateIndex - 1].fee : 0) +
        (aggregateData(fees, 'income', 'sum') || 0);

      // Trades, Net Profit
      const trades = tradeChunks[dateIndex];
      chartData[dateIndex].trades =
        (dateIndex > 0 ? chartData[dateIndex - 1].trades : 0) +
        trades.filter(t => isReduceOrder(t)).length;
      chartData[dateIndex].netProfit =
        (dateIndex > 0 ? chartData[dateIndex - 1].netProfit : 0) +
        aggregateData(trades, 'operationProfit', 'sum') +
        (aggregateData(trades, 'commission', 'sum') || 0);

      // BTC price
      const btcPrices = btcChunks[dateIndex];
      if (dateIndex === 0 && !btcPrices.length && btcPricesData.length) {
        chartData[dateIndex].btcPrice = btcPricesData[0].open;
      } else {
        chartData[dateIndex].btcPrice =
          aggregateData(btcPrices, 'open', 'average') ||
          (dateIndex > 0 ? chartData[dateIndex - 1].btcPrice : null);
      }
    }

    return chartData;
  }, [reportData, fromTs, toTs, klines]);

  const btcChartDomain = useMemo(() => {
    if (!chartData || chartData.length == 0) return undefined;

    const btcMinPrice = chartData.reduce(
      (min, data) =>
        min != null && data.btcPrice != null && min < data.btcPrice ? min : data.btcPrice,
      chartData[0].btcPrice
    );
    const btcMaxPrice = chartData.reduce(
      (max, data) =>
        max != null && data.btcPrice != null && max > data.btcPrice ? max : data.btcPrice,
      chartData[0].btcPrice
    );

    return btcMinPrice && btcMaxPrice
      ? [Math.round(btcMinPrice * 0.9), Math.round(btcMaxPrice * 1.1)]
      : undefined;
  }, [chartData]);

  const tooltipValueFormatter = useCallback((value: any, item: any) => {
    return (
      <span className="ml-2 flex gap-2 font-mono font-medium tabular-nums">
        {item.dataKey !== 'trades' ? `${formatNumber(value, 2)}$` : value}
      </span>
    );
  }, []);

  return (
    <div className="flex w-full flex-col">
      <ChartContainer config={chartConfig} className="max-h-[900px]">
        <LineChart data={chartData} accessibilityLayer>
          <CartesianGrid vertical={true} />
          <XAxis
            // allowDuplicatedCategory={false}
            dataKey="date"
            interval="equidistantPreserveStart"
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            tickFormatter={value => formatDate(new Date(value))}
          />
          <YAxis yAxisId="balanceY" tickLine={false} axisLine={false} mirror orientation="right" />
          <YAxis
            yAxisId="commissionY"
            hide={showBTCPrice}
            tickLine={false}
            axisLine={false}
            mirror
          />
          <YAxis
            yAxisId="btcPriceY"
            hide={!showBTCPrice}
            mirror
            tickLine={false}
            axisLine={false}
            domain={btcChartDomain}
          />
          <ChartTooltip
            content={
              <ChartTooltipContent
                labelFormatter={(_, payload) => {
                  if (payload.length) {
                    const date = payload[0].payload.date;
                    return formatDate(date);
                  }
                }}
                valueFormatter={tooltipValueFormatter}
              />
            }
          />

          {showEquity ? (
            <Line
              yAxisId="balanceY"
              dataKey="equity"
              name="Equity"
              type="monotone"
              stroke="var(--color-equity)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showAvailable ? (
            <Line
              yAxisId="balanceY"
              dataKey="available"
              name="Available"
              type="monotone"
              stroke="var(--color-available)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showPnl ? (
            <Line
              yAxisId="balanceY"
              dataKey="pnl"
              name="Unrealized PNL"
              type="monotone"
              stroke="var(--color-pnl)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showFunding ? (
            <Line
              yAxisId="commissionY"
              dataKey="funding"
              name="Funding"
              type="monotone"
              stroke="var(--color-funding)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showFee ? (
            <Line
              yAxisId="commissionY"
              dataKey="fee"
              name="Fee"
              type="monotone"
              stroke="var(--color-fee)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showTrades ? (
            <Line
              yAxisId="commissionY"
              dataKey="trades"
              name="Trades"
              type="monotone"
              stroke="var(--color-trades)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showNetProfit ? (
            <Line
              yAxisId="commissionY"
              dataKey="netProfit"
              name="Net profit"
              type="monotone"
              stroke="var(--color-profit)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
          {showBTCPrice ? (
            <Line
              yAxisId="btcPriceY"
              dataKey="btcPrice"
              name="BTC price"
              type="monotone"
              stroke="var(--color-btc)"
              strokeWidth={2}
              dot={false}
            />
          ) : null}
        </LineChart>
      </ChartContainer>

      <div className="flex items-center justify-center gap-4 whitespace-nowrap p-3">
        <Checkbox
          color="teal"
          label="Equity"
          checked={showEquity}
          onCheckedChange={val => setShowEquity(Boolean(val))}
        />
        <Checkbox
          color="green"
          label="Available"
          checked={showAvailable}
          onCheckedChange={val => setShowAvailable(Boolean(val))}
        />
        <Checkbox
          color="pink"
          label="PNL"
          checked={showPnl}
          onCheckedChange={val => setShowPnl(Boolean(val))}
        />
        <Checkbox
          color="purple"
          label="Trades"
          checked={showTrades}
          onCheckedChange={val => setShowTrades(Boolean(val))}
        />
        <Checkbox
          color="blue"
          label="Net profit"
          checked={showNetProfit}
          onCheckedChange={val => setShowNetProfit(Boolean(val))}
        />
        <Checkbox
          color="red"
          label="Funding"
          checked={showFunding}
          onCheckedChange={val => setShowFunding(Boolean(val))}
        />
        <Checkbox
          color="gray"
          label="Fee"
          checked={showFee}
          onCheckedChange={val => setShowFee(Boolean(val))}
        />
        <Checkbox
          color="yellow"
          label="BTC price"
          checked={showBTCPrice}
          onCheckedChange={val => setShowBTCPrice(Boolean(val))}
        />
      </div>
    </div>
  );
};
