import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pin } from 'lucide-react';
import { LabelList, Pie, PieChart } from 'recharts';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { usePositions } from '@/hooks/usePositions';
import { changeLightness } from '@/lib/colors';
import { formatNumber, round } from '@/lib/common';
import { Position, PositionSideEnum } from '@/types';

export const PositionValueChartCard = () => {
  const {
    tradePositionsValue,
    securityPositionsValue,
    tradePositions,
    positionsRequest: { isLoading: isPositionsLoading },
  } = usePositions();

  const [localPositions, setLocalPositions] = useState<Record<string, Position[]>>();
  const [localPositionsValue, setLocalPositionsValue] = useState<number>();

  useEffect(() => {
    if (
      tradePositions &&
      Object.keys(tradePositions).length > 0 &&
      (!localPositions || Object.keys(localPositions).length == 0)
    ) {
      const tradePositionsMap = tradePositions.reduce(
        (acc, position) => {
          if (position.symbol in acc) {
            acc[position.symbol].push(position);
          } else {
            acc[position.symbol] = [position];
          }
          return acc;
        },
        {} as Record<string, Position[]>
      );
      setLocalPositions(tradePositionsMap);
    }
  }, [tradePositions, localPositions]);

  useEffect(() => {
    if (tradePositionsValue && !localPositionsValue) setLocalPositionsValue(tradePositionsValue);
  }, [tradePositionsValue, localPositionsValue]);

  return (
    <Card className="min-w-[300px]">
      <CardHeader className="space-y-0 p-2">
        <CardTitle className="flex items-center text-lg font-medium text-secondary-foreground">
          <div className="flex items-center">
            <Pin className="mr-1 size-5" /> Trade Positions Value
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="px-4 pb-0 pt-2 text-2xl font-medium">
        <div>{!isPositionsLoading ? formatNumber(tradePositionsValue, 2) : '---'} $</div>
        <div className="mt-2 flex h-[240px] flex-col">
          <PositionsValueChart
            positionsMap={localPositions || {}}
            positionsValue={localPositionsValue || 0}
          />
        </div>
      </CardContent>
      <CardFooter className="justify-center p-2 text-sm text-third-foreground">
        BTC Short position: {formatNumber(securityPositionsValue, 2)}$
      </CardFooter>
    </Card>
  );
};

type ChartData = {
  symbol: string;
  value: number;
  fill: string;
  label?: string;
  longPercent: number;
  shortPercent: number;
  longValue: number;
  shortValue: number;
  longColor: string;
  shortColor: string;
};
const chartConfig = {};

type PositionsValueChartProps = {
  positionsMap: Record<string, Position[]>;
  positionsValue: number;
};
const PositionsValueChart = ({ positionsMap, positionsValue }: PositionsValueChartProps) => {
  const { getSymbolColor } = usePositions();

  const chartData = useMemo(() => {
    const data = Object.entries(positionsMap).reduce((acc, [symbol, positions]) => {
      const longPos = positions.find(p => p.positionSide == PositionSideEnum.LONG);
      const shortPos = positions.find(p => p.positionSide == PositionSideEnum.SHORT);

      if (longPos?.positionAmt !== 0 || shortPos?.positionAmt !== 0) {
        const longValue = Math.abs(longPos?.notional || 0);
        const shortValue = Math.abs(shortPos?.notional || 0);

        const longPercent = round((longValue / positionsValue) * 100, 2);
        const shortPercent = round((shortValue / positionsValue) * 100, 2);

        const longColor = getSymbolColor(symbol);
        const shortColor = changeLightness(longColor, -15);
        const commonProps = {
          symbol,
          longValue,
          shortValue,
          longPercent,
          shortPercent,
          longColor,
          shortColor,
        };
        if (longValue)
          acc.push({
            ...commonProps,
            label: longPercent > 2 ? symbol.slice(0, -4) : '',
            value: longPercent,
            fill: longColor,
          });
        if (shortValue)
          acc.push({
            ...commonProps,
            value: shortPercent,
            fill: shortColor,
          });
      }

      return acc;
    }, [] as ChartData[]);

    return data;
  }, [positionsMap, getSymbolColor, positionsValue]);

  const tooltipFormatter = useCallback((itemValue: any, _name: any, item: any) => {
    const payload = item.payload.payload;
    const isTargetShort = itemValue === payload.shortPercent;

    const positionSideLabel = (color: string, label: string, percent: number, value: number) => {
      return (
        <div className="flex items-center gap-2">
          <div
            className={'size-2.5 shrink-0 rounded-[2px] border-[--color-border] bg-[--color-bg]'}
            style={
              {
                '--color-bg': color,
                '--color-border': color,
              } as React.CSSProperties
            }
          />

          <div className="flex flex-1 items-center justify-between gap-2 leading-none">
            <span className="w-8 text-muted-foreground">{label}</span>
            <span className="font-mono font-medium tabular-nums">{percent}%</span>
            <span className="font-mono font-medium tabular-nums text-muted-foreground">
              {formatNumber(value, 0)}$
            </span>
          </div>
        </div>
      );
    };

    return (
      <div className={`flex gap-1 ${isTargetShort ? 'flex-col-reverse' : 'flex-col'}`}>
        {payload.longValue
          ? positionSideLabel(payload.longColor, 'Long', payload.longPercent, payload.longValue)
          : null}
        {payload.shortValue
          ? positionSideLabel(payload.shortColor, 'Short', payload.shortPercent, payload.shortValue)
          : null}
      </div>
    );
  }, []);

  return (
    <ChartContainer
      config={chartConfig}
      className="w-full grow pb-0 [&_.recharts-pie-label-text]:fill-foreground"
    >
      <PieChart margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
        <ChartTooltip
          content={
            <ChartTooltipContent
              labelFormatter={(_, payload) => payload[0]?.name || 'Symbol'}
              formatter={tooltipFormatter}
            />
          }
        />
        <Pie data={chartData} dataKey="value" nameKey="symbol" label>
          <LabelList dataKey="label" className="pointer-events-none" fontSize={10} />
        </Pie>
      </PieChart>
    </ChartContainer>
  );
};
